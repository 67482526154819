var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.2.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    return _.defaultsDeep(
        {
            jersey: mapJersey(prev.jersey),
            jerseyInside: prev.jerseyInside && mapJersey(prev.jerseyInside),
        },
        prev
    )
}

function mapJersey(prev) {
    return {
      options: {
        'sublimatedCollar': prev.options.ribKnitCollar,
        'sublimatedCuff': prev.options.ribKnitCuff,
      }
    }
}
