var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev){
    return {
        dataModelVersion: '3.0.1',
        designData: mapDesignData(prev.designData)
    }
}

function mapDesignData(prev){
    return _.cloneDeep(prev, function(value, key){
        if(_.isString(value)){
            if(_.startsWith(key, 'color_')){
                return mapColor(value)
            }
        }
    })
}

var colorMap = {
    'DM': 'MA',
}

function mapColor(prev){
    return colorMap[prev] || prev
}
