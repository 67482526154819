
exports.getTemplate = require('./getTemplate')

exports.upgradeVersion = {
    'from2.0.0to2.0.1': require('./upgradeVersion/from2.0.0to2.0.1'),
    'from2.0.1to2.0.2': require('./upgradeVersion/from2.0.1to2.0.2'),
    'from2.0.2to3.0.0': require('./upgradeVersion/from2.0.2to3.0.0'),
    'from3.0.0to3.0.1': require('./upgradeVersion/from3.0.0to3.0.1'),
    'from3.0.1to3.0.2': require('./upgradeVersion/from3.0.1to3.0.2'),
    'from3.0.2to3.0.3': require('./upgradeVersion/from3.0.2to3.0.3'),
    'from3to4': require('./upgradeVersion/from3to4'),
    'from4.0.0to4.1.0': require('./upgradeVersion/from4.0.0to4.1.0'),
    'from4.1.0to4.2.0': require('./upgradeVersion/from4.1.0to4.2.0'),
    'from4.2.0to4.3.0': require('./upgradeVersion/from4.2.0to4.3.0'),
    'from4.3.0to4.4.0': require('./upgradeVersion/from4.3.0to4.4.0'),
    'from4.4.0to4.5.0': require('./upgradeVersion/from4.4.0to4.5.0'),
    'from4.5.0to4.6.0': require('./upgradeVersion/from4.5.0to4.6.0'),
    'from4.6.0to4.7.0': require('./upgradeVersion/from4.6.0to4.7.0'),
    'from4.7.0to4.8.0': require('./upgradeVersion/from4.7.0to4.8.0'),
    'from4.8.0to4.9.0': require('./upgradeVersion/from4.8.0to4.9.0'),
    'from4.9.0to4.10.0': require('./upgradeVersion/from4.9.0to4.10.0'),
}

exports.getThinModel = require('./getThinModel')
exports.getSummary = require('./getSummary')
