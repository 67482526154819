var _ = require('lodash')
var config = require('data').config

var FOOTBALL = config.sportId === 'football'

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.5.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    if (FOOTBALL) {
        return prev
    }
    return _.defaultsDeep({
        jersey: mapGarment(prev.jersey),
        jerseyInside: prev.jerseyInside && mapGarment(prev.jerseyInside),
    }, prev)
}

function mapGarment(prev) {
    return {
        decoration: {
            watermarkText: removeWatermarkFill(prev.decoration.watermarkText),
            watermarkLogo: {
                stock: removeWatermarkFill(prev.decoration.watermarkLogo.stock),
            },
        },
    }
}

function removeWatermarkFill(prev) {
    if (prev == null) {
        return prev
    }
    return _.omit(prev, 'fill', 'outline_1', 'outline_2')
}
