module.exports={
    "none": {
        "icon": "assets/opPatterns/null.png?2014-09-30",
        "code": "none",
        "abbr": "NONE",
        "name": "None",
        "size": null,
        "pattern": null,
        "colorCount": 0,
        "whitelists": {
            "jersey": true,
            "op4": true,
            "op15": true
        }
    },
    "1color": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "1color",
        "abbr": null,
        "name": "1/8\" - #1",
        "size": "3/8\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": true,
            "op4": true,
            "op15": true
        }
    },
    "2color": {
        "icon": "assets/opPatterns/6.png?2014-09-30",
        "code": "2color",
        "abbr": null,
        "name": "3/8\" - #6",
        "size": "3/8\"",
        "pattern": "6",
        "colorCount": 2,
        "whitelists": {
            "jersey": true,
            "op4": true,
            "op15": true
        }
    },
    "3color": {
        "icon": "assets/opPatterns/3.png?2014-09-30",
        "code": "3color",
        "abbr": null,
        "name": "3/8\" - #3",
        "size": "3/8\"",
        "pattern": "3",
        "colorCount": 3,
        "whitelists": {
            "jersey": true,
            "op4": true,
            "op15": true
        }
    },
    "0125_1": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "0125_1",
        "abbr": null,
        "name": "1/8\" - #1",
        "size": "1/8\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0375_1": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "0375_1",
        "abbr": null,
        "name": "3/8\" - #1",
        "size": "3/8\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0375_3": {
        "icon": "assets/opPatterns/3.png?2014-09-30",
        "code": "0375_3",
        "abbr": null,
        "name": "3/8\" - #3",
        "size": "3/8\"",
        "pattern": "3",
        "colorCount": 3,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0375_6": {
        "icon": "assets/opPatterns/6.png?2014-09-30",
        "code": "0375_6",
        "abbr": null,
        "name": "3/8\" - #6",
        "size": "3/8\"",
        "pattern": "6",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0500_1": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "0500_1",
        "abbr": null,
        "name": "1/2\" - #1",
        "size": "1/2\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0500_2": {
        "icon": "assets/opPatterns/2.png?2014-09-30",
        "code": "0500_2",
        "abbr": null,
        "name": "1/2\" - #2",
        "size": "1/2\"",
        "pattern": "2",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0500_3": {
        "icon": "assets/opPatterns/3.png?2014-09-30",
        "code": "0500_3",
        "abbr": null,
        "name": "1/2\" - #3",
        "size": "1/2\"",
        "pattern": "3",
        "colorCount": 3,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0500_6": {
        "icon": "assets/opPatterns/6.png?2014-09-30",
        "code": "0500_6",
        "abbr": null,
        "name": "1/2\" - #6",
        "size": "1/2\"",
        "pattern": "6",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0750_1": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "0750_1",
        "abbr": null,
        "name": "3/4\" - #1",
        "size": "3/4\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0750_3": {
        "icon": "assets/opPatterns/3.png?2014-09-30",
        "code": "0750_3",
        "abbr": null,
        "name": "3/4\" - #3",
        "size": "3/4\"",
        "pattern": "3",
        "colorCount": 3,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "0750_6": {
        "icon": "assets/opPatterns/6.png?2014-09-30",
        "code": "0750_6",
        "abbr": null,
        "name": "3/4\" - #6",
        "size": "3/4\"",
        "pattern": "6",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1000_1": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "1000_1",
        "abbr": null,
        "name": "1\" - #1",
        "size": "1\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1000_2": {
        "icon": "assets/opPatterns/2.png?2014-09-30",
        "code": "1000_2",
        "abbr": null,
        "name": "1\" - #2",
        "size": "1\"",
        "pattern": "2",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1000_3": {
        "icon": "assets/opPatterns/3.png?2014-09-30",
        "code": "1000_3",
        "abbr": null,
        "name": "1\" - #3",
        "size": "1\"",
        "pattern": "3",
        "colorCount": 3,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1000_6": {
        "icon": "assets/opPatterns/6.png?2014-09-30",
        "code": "1000_6",
        "abbr": null,
        "name": "1\" - #6",
        "size": "1\"",
        "pattern": "6",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1500_1": {
        "icon": "assets/opPatterns/1.png?2014-09-30",
        "code": "1500_1",
        "abbr": null,
        "name": "1 1/2\" - #1",
        "size": "1_1/2\"",
        "pattern": "1",
        "colorCount": 1,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1500_2": {
        "icon": "assets/opPatterns/2.png?2014-09-30",
        "code": "1500_2",
        "abbr": null,
        "name": "1 1/2\" - #2",
        "size": "1_1/2\"",
        "pattern": "2",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1500_3": {
        "icon": "assets/opPatterns/3.png?2014-09-30",
        "code": "1500_3",
        "abbr": null,
        "name": "1 1/2\" - #3",
        "size": "1_1/2\"",
        "pattern": "3",
        "colorCount": 3,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    },
    "1500_6": {
        "icon": "assets/opPatterns/6.png?2014-09-30",
        "code": "1500_6",
        "abbr": null,
        "name": "1 1/2\" - #6",
        "size": "1_1/2\"",
        "pattern": "6",
        "colorCount": 2,
        "whitelists": {
            "jersey": false,
            "op4": false,
            "op15": false
        }
    }
}