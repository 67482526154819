var THREE = require('three.js-xdomain')

module.exports = function getCustomPhongShader() {
    return {
        getUniforms: function(){
            return THREE.UniformsUtils.merge( [
                THREE.UniformsLib[ 'common' ],
                THREE.UniformsLib[ 'lightmap' ],
                THREE.UniformsLib[ 'emissivemap' ],
                THREE.UniformsLib[ 'bumpmap' ],
                THREE.UniformsLib[ 'displacementmap' ],
                THREE.UniformsLib[ 'fog' ],
                THREE.UniformsLib[ 'lights' ],
                THREE.UniformsLib[ 'shadowmap' ],

                {
                    'emissive' : { type: 'c', value: new THREE.Color( 0x000000 ) },
                    'specular' : { type: 'c', value: new THREE.Color( 0x151515 ) },
                    'shininess': { type: 'f', value: 4 },
                    'detailmap': {type: 't', value: new THREE.Texture()},
                    'insidemap': {type: 't', value: new THREE.Texture()},
                    'map': {type: 't', value: new THREE.Texture()},
                    'normalMap' : { type: 't', value: new THREE.Texture() },
                    'normalScale' : { type: 'v2', value: new THREE.Vector2( 1, 1 ) }

                }

            ] )
        },


        vertexShader: [

            '#define PHONG',

            'varying vec3 vViewPosition;',

            '#ifndef FLAT_SHADED',

            '	varying vec3 vNormal;',

            '#endif',

            THREE.ShaderChunk[ 'common' ],
            THREE.ShaderChunk[ 'uv_pars_vertex' ],
            THREE.ShaderChunk[ 'uv2_pars_vertex' ],
            THREE.ShaderChunk[ 'displacementmap_pars_vertex' ],
            THREE.ShaderChunk[ 'envmap_pars_vertex' ],
            THREE.ShaderChunk[ 'lights_phong_pars_vertex' ],
            THREE.ShaderChunk[ 'color_pars_vertex' ],
            THREE.ShaderChunk[ 'shadowmap_pars_vertex' ],

            'void main() {',

            THREE.ShaderChunk[ 'uv_vertex' ],
            THREE.ShaderChunk[ 'uv2_vertex' ],
            THREE.ShaderChunk[ 'color_vertex' ],

            THREE.ShaderChunk[ 'beginnormal_vertex' ],
            THREE.ShaderChunk[ 'defaultnormal_vertex' ],

            '#ifndef FLAT_SHADED', // Normal computed with derivatives when FLAT_SHADED

            '	vNormal = normalize( transformedNormal );',

            '#endif',

            THREE.ShaderChunk[ 'begin_vertex' ],
            THREE.ShaderChunk[ 'project_vertex' ],

            '	vViewPosition = - mvPosition.xyz;',

            THREE.ShaderChunk[ 'worldpos_vertex' ],
            THREE.ShaderChunk[ 'envmap_vertex' ],
            THREE.ShaderChunk[ 'lights_phong_vertex' ],
            THREE.ShaderChunk[ 'shadowmap_vertex' ],

            '}'

        ].join( '\n' ),

        fragmentShader: [
            //'#extension GL_OES_standard_derivatives : enable',
            '#define PHONG',

            'uniform vec3 diffuse;',
            'uniform vec3 emissive;',
            'uniform vec3 specular;',
            'uniform float shininess;',
            'uniform float opacity;',
            'uniform sampler2D detailmap;',
            'uniform sampler2D insidemap;',

            THREE.ShaderChunk[ 'common' ],
            THREE.ShaderChunk[ 'color_pars_fragment' ],
            THREE.ShaderChunk[ 'uv_pars_fragment' ],
            THREE.ShaderChunk[ 'uv2_pars_fragment' ],
            THREE.ShaderChunk[ 'map_pars_fragment' ],
            THREE.ShaderChunk[ 'alphamap_pars_fragment' ],
            THREE.ShaderChunk[ 'aomap_pars_fragment' ],
            THREE.ShaderChunk[ 'lightmap_pars_fragment' ],
            THREE.ShaderChunk[ 'emissivemap_pars_fragment' ],

            THREE.ShaderChunk[ 'lights_phong_pars_fragment' ],
            THREE.ShaderChunk[ 'shadowmap_pars_fragment' ],
            THREE.ShaderChunk[ 'bumpmap_pars_fragment' ],
            THREE.ShaderChunk[ 'normalmap_pars_fragment' ],
            THREE.ShaderChunk[ 'specularmap_pars_fragment' ],
            THREE.ShaderChunk[ 'logdepthbuf_pars_fragment' ],

            'vec3 rgb2hsv(vec3 c){',
            'vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);',
            'vec4 p = c.g < c.b ? vec4(c.bg, K.wz) : vec4(c.gb, K.xy);',
            'vec4 q = c.r < p.x ? vec4(p.xyw, c.r) : vec4(c.r, p.yzx);',
            'float d = q.x - min(q.w, q.y);',
            'float e = 1.0e-10;',
            'return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);}',

            'vec3 hsv2rgb(vec3 c)',
            '{ vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);',
            'vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);',
            'return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);}',

            'float linearMapping(float val, vec2 src, vec2 dst) {return ((val - src.x) / (src.y - src.x)) * (dst.y - dst.x) + dst.x;}',

            'void main() {',

            '	vec3 outgoingLight = vec3( 0.0 );',
            '	vec4 diffuseColor = vec4( diffuse, opacity );',
            '	vec3 totalAmbientLight = ambientLightColor;',
            '	vec3 totalEmissiveLight = emissive;',
            '	vec3 shadowMask = vec3( 1.0 );',

            THREE.ShaderChunk[ 'map_fragment' ],
            THREE.ShaderChunk[ 'color_fragment' ],
            THREE.ShaderChunk[ 'alphamap_fragment' ],
            THREE.ShaderChunk[ 'alphatest_fragment' ],
            THREE.ShaderChunk[ 'specularmap_fragment' ],
            THREE.ShaderChunk[ 'normal_phong_fragment' ],
            THREE.ShaderChunk[ 'lightmap_fragment' ],
            THREE.ShaderChunk[ 'hemilight_fragment' ],

            THREE.ShaderChunk[ 'emissivemap_fragment' ],

            THREE.ShaderChunk[ 'lights_phong_fragment' ],
            THREE.ShaderChunk[ 'shadowmap_fragment' ],

            'totalDiffuseLight *= shadowMask;',

            'totalSpecularLight *= shadowMask;',

            '#ifdef METAL',

            '	outgoingLight += diffuseColor.rgb * ( totalDiffuseLight + totalAmbientLight ) * specular + totalSpecularLight + totalEmissiveLight;',

            '#else',

            '	outgoingLight += diffuseColor.rgb * ( totalDiffuseLight + totalAmbientLight ) + totalSpecularLight + totalEmissiveLight;',

            '#endif',

            //'#ifdef USE_AOMAP\n\n	outgoingLight *= ( texture2D( aoMap, vUv ).r - 1.0 ) * aoMapIntensity + 1.0;\n\n#endif\n',
            THREE.ShaderChunk[ 'linear_to_gamma_fragment' ],
            //remapping value of outgoing light to [0.05 - 0.95] range
            'vec3 hsvLight = rgb2hsv(outgoingLight);',
            'hsvLight.z = linearMapping(hsvLight.z, vec2(0, 1), vec2(0.03, 0.95));',
            'outgoingLight = hsv2rgb(hsvLight);',
            'if (gl_FrontFacing) { gl_FragColor = vec4( outgoingLight, diffuseColor.a);} else {vec4 insideTex = texture2D( insidemap, vUv ); gl_FragColor = insideTex * vec4(0.7, 0.7, 0.7, 1.0);}',

            '}'

        ].join( '\n' )
    }
}
