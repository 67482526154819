var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev){
    return {
        dataModelVersion: '3.0.0',
        designData: mapDesignData(prev.designData)
    }
}

function mapDesignData(prev){
    var template = require('../getTemplate')(prev.designTemplate)
    var next = _.defaultsDeep({
        jersey: mapJersey(prev.jersey),
        pant: mapPant(prev.pant),
    }, template)
    return _.cloneDeep(next, function(value, key){
        if(_.isString(value)){
            if(_.startsWith(key, 'color_')){
                return mapColor(value)
            }
            if(_.startsWith(key, 'enabled')){
                return mapEnabled(value)
            }
        }
    })
}

function mapJersey(prev){
    return {
        model: prev.model, // TODO is this still needed?
        colorAreas: prev.colorAreas,
        decoration: {
            type: mapDecorationPackade(prev.decoration.type),
            teamName: prev.teamName,
            number: mapJerseyNumber(prev.number),
            logo: splitLogoInCustomAndStock(prev.logo),
        },
        options: {
            OP1: prev.decoration.OP1,
            OP2: prev.decoration.OP2,
            OP3: prev.decoration.OP3,
            ribKnitCollar: prev.decoration.ribKnitCollar,
            ribKnitCuff: prev.decoration.ribKnitCuff,
            buttonColor: prev.buttonColor,
            logoColor: prev.rawlingsLogoColor,
        },
    }
}

function mapPant(prev){
    return {
        model: prev.model, // TODO is this still needed?
        colorAreas: prev.colorAreas,
        decoration: {
            type: mapDecorationPackade(prev.decoration.type),
        },
        options: {
            OP4: prev.decoration.OP4,
            OP4B: prev.decoration.OP4B,
            OP4C: prev.decoration.OP4C,
            OP15: prev.decoration.OP15,
            logoColor: prev.rawlingsLogoColor,
        },
    }
}

function mapDecorationPackade(old){
    if(old === 'fullSublimation' || old === 'pantDeco'){
        return old
    }
    return old.toLowerCase()
}

function mapJerseyNumber(prev) {
    return {
        front: _.defaults({text: prev.text, font: mapNumberFont(prev.front.font)}, prev.front),
        back: _.defaults({text: prev.text, font: mapNumberFont(prev.back.font), placement: prev.back.placement === '30N' ? '30' : prev.back.placement }, prev.back),
        sleeve: {text: prev.text},
    }
}

var numberFonts = [
    'anaheimNumber',
    'arizona47',
    'brushScriptNumber',
    'connecticutNumber',
    'fancy47',
    'fullBlockNumber',
    'marylandNumber',
    'methodNumber',
    'miami47',
    'plainBlockNumber',
    'proBlockNumber',
]
function mapNumberFont(font){
    return _.find(numberFonts, function(testFont){
        return testFont !== font && _.startsWith(testFont, font)
    })
}

function splitLogoInCustomAndStock(prev){
    return {
        stock: {
            design: prev.design,
            size: prev.size,
            placement: prev.placement,
            fill: prev.fill,
        },
        custom: {
            customFile: prev.customFile,
            size: prev.size,
            placement: prev.placement,
        }
    }
}

var colorMap = {
    'K': 'KG',
    'W-B-S': 'W-S-B',
    'W-N-S': 'W-S-N',
    'BG-B-S': 'BG-S-B',
}

function mapColor(prev){
    return colorMap[prev] || prev
}


function mapEnabled(prev){
    var toggleMap = {
        'yes': 'on',
        'no': 'off',
    }
    return toggleMap[prev]
}
