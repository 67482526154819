var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.1.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    return _.defaultsDeep(
        {
            designTemplate: renameTemplate(prev.designTemplate),
            jersey: mapJersey(prev.jersey),
            jerseyInside: prev.jerseyInside && mapJersey(prev.jerseyInside),
            pant: {},
        },
        fixCracklePatternOrderDeep(prev)
    )
}

function mapJersey(prev) {
    return {
        model: renameTemplate(prev.model),
        colorAreas: {
            area4: area4ForCPVN_Sublimated(prev),
        },
        decoration: {
            type: getDecoType(prev.decoration.type),
        },
    }
}

function renameTemplate(prev) {
    if(prev == null) {
      return prev
    }
    return prev
    //  BASEBALL
      .replace('CS5-sublimated', 'CS5VF')
      .replace('CSSF-sublimated', 'CSSFVF')
      .replace('CJDI-sublimated', 'CJDIVF')
      .replace('CS2-sublimated', 'CS2VF')
      .replace('CJP2-sublimated', 'CJP2VF')
      .replace('BBSS-sublimated', 'BBSSVF')
      .replace('CJ2BI-sublimated', 'CJ2BIVF')
      .replace('ADBPJ13-sublimated', 'ADBPJ13VF')
      .replace('KLBPJ-sublimated', 'KLBPJVF')

    // SOFTBALL
      .replace('CW2B-sub', 'CW2BVF')
      .replace('CWJI-sub', 'CWJIVF')
      .replace('CWMFF-sub', 'CWMFFVF')
      .replace('FXCFJ-SS-sub', 'FXCFJVF-SS')
}

function area4ForCPVN_Sublimated(prev) {
    if (prev.model === 'CPVN-sublimated') {
        return {
            pattern: 'solidColor',
            color_1: prev.colorAreas.area3.color_1,
        }
    }
}

function getDecoType(prev) {
    return prev.replace('pp', 'sbpt')
}

function fixCracklePatternOrderDeep(prev){
    return _.cloneDeep(prev, function(value, key){
        if(key === 'fill' || _.startsWith(key, 'area')){
            if(value.pattern === 'crackle') {
                return _.defaults({
                  color_1: value.color_2,
                  color_2: value.color_1,
                }, value)
            }
        }
    })
}
