var _ = require('lodash')
var config = require('data').config
var garments = require('data').sheets.garments

var FOOTBALL = config.sportId === 'football'

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.7.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    if (!FOOTBALL) {
        return prev
    }
    var garment = garments[prev.designTemplate]
    var isReversible = garment != null && garment.isReversible === true

    return _.defaultsDeep(
        {
            jersey: mapGarment(prev.jersey, isReversible),
            jerseyInside: _.omit(prev.jerseyInside, ['model', 'colorAreas']),
        },
        prev
    )
}

function mapGarment(prev, isReversible) {
    if (!isReversible) return prev

    if (prev.model === 'RVFJFG') {
        return {
            colorAreas: {
                area1: {
                    pattern: prev.model,
                    color_1: prev.colorAreas.area2.color_1,
                },
                area2: {
                    pattern: prev.model,
                    color_1: prev.colorAreas.area4.color_1,
                },
                area3: prev.colorAreas.area5,
            },
        }
    }

    return {
        colorAreas: {
            area1: {
                pattern: prev.model,
                color_1: prev.colorAreas.area1.color_1,
                color_2: prev.colorAreas.area2.color_1,
            },
            area2: {
                pattern: prev.model,
                color_1: prev.colorAreas.area3.color_1,
                color_2: prev.colorAreas.area4.color_1,
            },
            area3: prev.colorAreas.area5,
        },
    }
}
