var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev){
    var next = _.cloneDeep(prev)

    next.dataModelVersion = '2.0.2'
    next.designData.jersey.logo.customFile = ''

    return next
}
