module.exports={
    "1": {
        "name": "1 inch",
        "code": "1",
        "abbr": "1\""
    },
    "2": {
        "name": "2 inch",
        "code": "2",
        "abbr": "2\""
    },
    "3": {
        "name": "3 inch",
        "code": "3",
        "abbr": "3\""
    },
    "4": {
        "name": "4 inch",
        "code": "4",
        "abbr": "4\""
    },
    "5": {
        "name": "5 inch",
        "code": "5",
        "abbr": "5\""
    },
    "6": {
        "name": "6 inch",
        "code": "6",
        "abbr": "6\""
    },
    "8": {
        "name": "8 inch",
        "code": "8",
        "abbr": "8\""
    },
    "10": {
        "name": "10 inch",
        "code": "10",
        "abbr": "10\""
    },
    "12": {
        "name": "12 inch",
        "code": "12",
        "abbr": "12\""
    },
    "0.125": {
        "name": "1/8 inch",
        "code": "0.125",
        "abbr": "1/8\""
    },
    "0.375": {
        "name": "3/8 inch",
        "code": "0.375",
        "abbr": "3/8\""
    },
    "0.75": {
        "name": "3/4 inch",
        "code": "0.75",
        "abbr": "3/4\""
    },
    "1.5": {
        "name": "1.5 inch",
        "code": "1.5",
        "abbr": "1.5\""
    },
    "2.5": {
        "name": "2.5 inch",
        "code": "2.5",
        "abbr": "2.5\""
    },
    "3.5": {
        "name": "3.5 inch",
        "code": "3.5",
        "abbr": "3.5\""
    }
}