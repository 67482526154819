module.exports={
    "select": {
        "code": "select",
        "name": "Select"
    },
    "fabricColors": {
        "code": "fabricColors",
        "name": "Fabric Colors"
    },
    "options": {
        "code": "options",
        "name": "Options"
    },
    "decorate": {
        "code": "decorate",
        "name": "Decorate"
    }
}