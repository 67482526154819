module.exports={
    "on": {
        "code": "on",
        "name": "On",
        "value": "On"
    },
    "off": {
        "code": "off",
        "name": "Off",
        "value": "Off"
    }
}