module.exports={
    "solidColor": {
        "icon": null,
        "name": "Solid",
        "code": "solidColor",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": null,
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "argyle": {
        "icon": "assets/pattern-icons/argyle.png?2014-09-30",
        "name": "Argyle",
        "code": "argyle",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 4,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "autoCamo": {
        "icon": "assets/pattern-icons/autoCamo.png?2014-09-30",
        "name": "Auto Camo",
        "code": "autoCamo",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": "camo",
        "format": "svg",
        "colorCount": 1,
        "autoColorCount": 3,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "autoDigitalCamo": {
        "icon": "assets/pattern-icons/autoDigitalCamo.png?2014-09-30",
        "name": "Auto Digital Camo",
        "code": "autoDigitalCamo",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": "digitalCamo",
        "format": "svg",
        "colorCount": 1,
        "autoColorCount": 3,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "bustedDots": {
        "icon": "assets/pattern-icons/bustedDots.png?2014-09-30",
        "name": "Busted Dots",
        "code": "bustedDots",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "bustedHoneycomb": {
        "icon": "assets/pattern-icons/bustedHoneycomb.png?2014-09-30",
        "name": "Busted Honeycomb",
        "code": "bustedHoneycomb",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "camo": {
        "icon": "assets/pattern-icons/camo.png?2014-09-30",
        "name": "Camo",
        "code": "camo",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "carbonBlock": {
        "icon": "assets/pattern-icons/carbonBlock.png?2014-09-30",
        "name": "Carbon Block",
        "code": "carbonBlock",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "carbonFiber": {
        "icon": "assets/pattern-icons/carbonFiber.png?2014-09-30",
        "name": "Carbon Fiber",
        "code": "carbonFiber",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": null,
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "check": {
        "icon": "assets/pattern-icons/check.png?2014-09-30",
        "name": "Check",
        "code": "check",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "check5": {
        "icon": "assets/pattern-icons/check5.png?2014-09-30",
        "name": "Check.5",
        "code": "check5",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "digitalCamo": {
        "icon": "assets/pattern-icons/digitalCamo.png?2014-09-30",
        "name": "Digital Camo",
        "code": "digitalCamo",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "digitalWave": {
        "icon": "assets/pattern-icons/digitalWave.png?2014-09-30",
        "name": "Digital Wave",
        "code": "digitalWave",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "char",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "char",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "electric": {
        "icon": "assets/pattern-icons/electric.png?2014-09-30",
        "name": "Electric",
        "code": "electric",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": 4752,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": 1584,
            "patternHeightScale": null
        }
    },
    "fade": {
        "icon": "assets/pattern-icons/fade.png?2014-09-30",
        "name": "Fade",
        "code": "fade",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "fit",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "fit",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "flannel": {
        "icon": "assets/pattern-icons/flannel.png?2014-09-30",
        "name": "Flannel",
        "code": "flannel",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 0,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "geo": {
        "icon": "assets/pattern-icons/geo.png?2014-09-30",
        "name": "Geo",
        "code": "geo",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "gradientDots": {
        "icon": "assets/pattern-icons/gradientDots.png?2014-09-30",
        "name": "Gradient Dots",
        "code": "gradientDots",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "gradientPlaid": {
        "icon": "assets/pattern-icons/gradientPlaid.png?2014-09-30",
        "name": "Gradient Plaid",
        "code": "gradientPlaid",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "hound": {
        "icon": "assets/pattern-icons/hound.png?2014-09-30",
        "name": "Hound",
        "code": "hound",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "plaid": {
        "icon": "assets/pattern-icons/plaid.png?2014-09-30",
        "name": "Plaid",
        "code": "plaid",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "plated": {
        "icon": "assets/pattern-icons/plated.png?2014-09-30",
        "name": "Plated",
        "code": "plated",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "proStripe": {
        "icon": "assets/pattern-icons/proStripe.png?2014-09-30",
        "name": "Pro Stripe",
        "code": "proStripe",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "scratch": {
        "icon": "assets/pattern-icons/scratch.png?2014-09-30",
        "name": "Scratch",
        "code": "scratch",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "char",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "char",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "shutter": {
        "icon": "assets/pattern-icons/shutter.png?2014-09-30",
        "name": "Shutter",
        "code": "shutter",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "splat": {
        "icon": "assets/pattern-icons/splat.png?2014-09-30",
        "name": "Splat",
        "code": "splat",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": 648,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": 648,
            "patternHeightScale": null
        }
    },
    "split": {
        "icon": "assets/pattern-icons/split.png?2014-09-30",
        "name": "Split",
        "code": "split",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile-horizontal",
            "patternHeight": null,
            "patternHeightScale": 1
        }
    },
    "tieDye": {
        "icon": "assets/pattern-icons/tieDye.png?2014-09-30",
        "name": "Tie Dye",
        "code": "tieDye",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "crackle": {
        "icon": "assets/pattern-icons/crackle.png?2014-09-30",
        "name": "Crackle",
        "code": "crackle",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "triadStripe": {
        "icon": "assets/pattern-icons/triadStripe.png?2014-09-30",
        "name": "Triad Stripe",
        "code": "triadStripe",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "svg",
        "colorCount": 4,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "heather": {
        "icon": "assets/pattern-icons/heather.png?2014-09-30",
        "name": "Heather",
        "code": "heather",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 1,
        "autoColorCount": 2,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "zebraDots": {
        "icon": "assets/pattern-icons/zebraDots.png?2014-09-30",
        "name": "Zebra Dots",
        "code": "zebraDots",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": null,
        "format": "png",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": 3000,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": 3000,
            "patternHeightScale": null
        }
    },
    "houndsTooth": {
        "icon": "assets/pattern-icons/houndsTooth.png?2014-09-30",
        "name": "Hounds Tooth",
        "code": "houndsTooth",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": false,
        "reuseAsset": "hound",
        "format": "svg",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": true,
            "fitMode": "tile",
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "rvfj4": {
        "icon": "assets/pattern-icons/rvfj4.jpg?2014-09-30",
        "name": "RVFJ4",
        "code": "rvfj4",
        "colorNames": [
            "Upper Body",
            "Lower Body",
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "rvfj7": {
        "icon": "assets/pattern-icons/rvfj7.jpg?2014-09-30",
        "name": "RVFJ7",
        "code": "rvfj7",
        "colorNames": [
            "Wing Design",
            "Body",
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "rvfj8": {
        "icon": "assets/pattern-icons/rvfj8.jpg?2014-09-30",
        "name": "RVFJ8",
        "code": "rvfj8",
        "colorNames": [
            "Sleeve",
            "Body",
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "rvfjfg": {
        "icon": "assets/pattern-icons/rvfjfg.jpg?2014-09-30",
        "name": "RVFJFG",
        "code": "rvfjfg",
        "colorNames": [
            "Body",
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "rvfjmz": {
        "icon": "assets/pattern-icons/rvfjmz.jpg?2014-09-30",
        "name": "RVFJMZ",
        "code": "rvfjmz",
        "colorNames": [
            "Sleeve",
            "Body",
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "ohio": {
        "icon": "assets/pattern-icons/ohio.png?2014-09-30",
        "name": "Ohio",
        "code": "ohio",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "streak": {
        "icon": "assets/pattern-icons/streak.png?2014-09-30",
        "name": "Streak",
        "code": "streak",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "predator": {
        "icon": "assets/pattern-icons/predator.png?2014-09-30",
        "name": "Predator",
        "code": "predator",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "bearcat": {
        "icon": "assets/pattern-icons/bearcat.png?2014-09-30",
        "name": "Bearcat",
        "code": "bearcat",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "wingback": {
        "icon": "assets/pattern-icons/wingback.png?2014-09-30",
        "name": "Wingback",
        "code": "wingback",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "aggie": {
        "icon": "assets/pattern-icons/aggie.png?2014-09-30",
        "name": "Aggie",
        "code": "aggie",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "flag": {
        "icon": "assets/pattern-icons/flag.png?2014-09-30",
        "name": "Flag",
        "code": "flag",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 0,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "digitalWave-element": {
        "icon": null,
        "name": "Digital Wave",
        "code": "digitalWave-element",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "tigerStripe": {
        "icon": null,
        "name": "Tiger Stripe",
        "code": "tigerStripe",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "ribKnit": {
        "icon": "assets/pattern-icons/ribKnit.png?2014-09-30",
        "name": "Rib Knit",
        "code": "ribKnit",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "ribKnitPattern01": {
        "icon": "assets/pattern-icons/ribKnitPattern01.png?2014-09-30",
        "name": "Rib Knit Pattern 01",
        "code": "ribKnitPattern01",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "ribKnitPattern02": {
        "icon": "assets/pattern-icons/ribKnitPattern02.png?2014-09-30",
        "name": "Rib Knit Pattern 02",
        "code": "ribKnitPattern02",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "ribKnitPattern03": {
        "icon": "assets/pattern-icons/ribKnitPattern03.png?2014-09-30",
        "name": "Rib Knit Pattern 03",
        "code": "ribKnitPattern03",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "ribKnitPattern06": {
        "icon": "assets/pattern-icons/ribKnitPattern06.png?2014-09-30",
        "name": "Rib Knit Pattern 06",
        "code": "ribKnitPattern06",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": null,
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "pattern01": {
        "icon": "assets/pattern-icons/pattern01.png?2014-09-30",
        "name": "Pattern 01",
        "code": "pattern01",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": "ribKnitPattern01",
        "format": "element",
        "colorCount": 1,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "pattern02": {
        "icon": "assets/pattern-icons/pattern02.png?2014-09-30",
        "name": "Pattern 02",
        "code": "pattern02",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": "ribKnitPattern02",
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "pattern03": {
        "icon": "assets/pattern-icons/pattern03.png?2014-09-30",
        "name": "Pattern 03",
        "code": "pattern03",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": "ribKnitPattern03",
        "format": "element",
        "colorCount": 3,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    },
    "pattern06": {
        "icon": "assets/pattern-icons/pattern06.png?2014-09-30",
        "name": "Pattern 06",
        "code": "pattern06",
        "colorNames": [
            null,
            null,
            null,
            null
        ],
        "hasAsset": true,
        "isLayoutElement": true,
        "reuseAsset": "ribKnitPattern06",
        "format": "element",
        "colorCount": 2,
        "autoColorCount": 0,
        "garmentConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        },
        "letteringConfig": {
            "colorize": false,
            "fitMode": null,
            "patternHeight": null,
            "patternHeightScale": null
        }
    }
}