var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.8.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    return _.defaultsDeep(
        {
            designTemplate: getNewModel(prev.designTemplate),
            jersey: {
                model: getNewModel(prev.jersey.model),
            },
        },
        prev
    )
}

function getNewModel(prev) {
    return prev.replace('LJDIV47F', 'LJDIV47VF')
}
