var Q = require('q')

module.exports = function getImageLoader (config){
    var deferred = null
    var image = document.createElement( 'img' )
    image.crossOrigin = config.crossOrigin

    image.addEventListener('load', function () {
        deferred.resolve(image)
        deferred = null
    }, false )

    // image.addEventListener( 'progress', function ( event ) {
    // 	onProgress( event );
    // }, false );

    image.addEventListener('error', function ( event ) {
        deferred.reject(new Error('Failed to load image ' + image.src + ' with error ' + event.message))
        delete image.src
    }, false )

    return {
        load: function(url){
            deferred = Q.defer()
            delete image.src // force reset to ensure a new loading process
            image.src = url
            return deferred.promise
        },
        cancel: function(){
            deferred && deferred.reject('Cancelled')
            image.src = null
        }
    }
}
