module.exports={
    "straight": {
        "code": "straight",
        "name": "Straight",
        "canHaveTailing": true
    },
    "angled": {
        "code": "angled",
        "name": "Angled",
        "canHaveTailing": true
    },
    "verticalArch": {
        "code": "verticalArch",
        "name": "Vertical Arch",
        "canHaveTailing": false
    },
    "slanted": {
        "code": "slanted",
        "name": "Slanted",
        "canHaveTailing": true
    },
    "bridgedArch": {
        "code": "bridgedArch",
        "name": "Bridged Arch",
        "canHaveTailing": false
    }
}