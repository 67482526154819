var _ = require('lodash')
var config = require('data').config

var FOOTBALL = config.sportId === 'football'

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.6.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    if (!FOOTBALL) {
        return prev
    }
    return _.defaultsDeep({
        jersey: mapGarment(prev.jersey),
        jerseyInside: prev.jerseyInside && mapGarment(prev.jerseyInside),
    }, prev)
}

function mapGarment(prev) {
    return {
        options: {
          sleeveStripes: mapSleeveStripes(prev.decoration.sleeveStripes)
        }
    }
}

function mapSleeveStripes(prev) {
  return {
    type: prev.placement === 'none' ? 'none' : prev.pattern,
    color_1: prev.fill.color_1,
    color_2: prev.fill.color_2,
  }
}
