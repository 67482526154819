var THREE = require('three.js-xdomain')
var OrbitControls = require('./three-orbit-controls')(THREE)

var customPhongShader = require('./getCustomPhongShader')()

module.exports = {
    getSceneDefs: function getSceneDefs(scene){
        var models = {
            base: scene,
        }
        var materials = {
            base: module.exports.getMaterial(THREE.DoubleSide),
            outside: module.exports.getMaterial(THREE.FrontSide),
            inside: module.exports.getMaterial(THREE.FrontSide),
        }
        var textures = {
            outside: materials.base.uniforms.map.value,
            inside: materials.base.uniforms.insidemap.value,
            normals: materials.base.uniforms.normalMap.value,
        }

        materials.outside.uniforms.map.value = textures.outside
        materials.outside.uniforms.normalMap.value = textures.normals

        materials.inside.uniforms.map.value = textures.inside
        materials.inside.uniforms.normalMap.value = textures.normal

        return {
            models: models,
            materials: materials,
            textures: textures,
        }
    },

    getRenderer: function getRenderer() {
        var renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } )
        renderer.setPixelRatio( window.devicePixelRatio )
        return renderer
    },

    getCamera: function getCamera(){
        var camera = new THREE.PerspectiveCamera(27, 1000 / 1000, 0.1, 10)
        camera.position.z = 2.0
        return camera
    },

    getControls: function getControls(camera, domElement){
        var controls = new OrbitControls( camera, domElement )
        controls.enabled = true
        controls.enableZoom = true
        controls.enableRotate = true
        controls.enablePan = false
        controls.enableDamping = true
        controls.dampingFactor = 0.1
        controls.minDistance = 2/3
        controls.maxDistance = 2
        controls.peekDistance = 0.3
        controls.minPolarAngle = Math.PI/2 - Math.PI/2/4
        controls.maxPolarAngle = Math.PI/2 + Math.PI/2/4
        return controls
    },

    addLights: function addLights(scene){
        var light
        light = new THREE.DirectionalLight( 0xffffff )
        light.intensity = 0.15
        light.position.set(4, 1.5, 4)
        light.target.position.set(0, 0, 0)
        light.updateMatrixWorld()
        light.target.updateMatrixWorld()
        scene.add(light)
        //
        // //frontLeftLight
        light = new THREE.DirectionalLight( 0xffffff )
        light.intensity = 0.15
        light.position.set(-4, 1.5, 4)
        light.target.position.set(0, 0, 0)
        light.updateMatrixWorld()
        light.target.updateMatrixWorld()
        scene.add(light)
        //
        //backLeftLight
        light = new THREE.DirectionalLight( 0xffffff )
        light.intensity = 0.15
        light.position.set(-10, 1.5, -10)
        light.target.position.set(0, 0, 0)
        light.updateMatrixWorld()
        light.target.updateMatrixWorld()
        scene.add(light)

        //backRightLight
        light = new THREE.DirectionalLight( 0xffffff )
        light.intensity = 0.15
        light.position.set(10, 1.5, -10)
        light.target.position.set(0, 0, 0)
        light.updateMatrixWorld()
        light.target.updateMatrixWorld()
        scene.add(light)

        light = new THREE.HemisphereLight( 0xffffff, 0xcccccc, 0.35 )
        //light.color.setHSL( 0, 1, 0.9 )
        light.position.set( 0, 6, 0 )
        scene.add( light )
    },

    getMaterial: function getMaterial(side) {
        var defines = {}
        defines['USE_MAP'] = ''
        defines['USE_NORMALMAP'] = ''

        var material =  new THREE.ShaderMaterial({
            defines: defines,
            uniforms: customPhongShader.getUniforms(),
            vertexShader: customPhongShader.vertexShader,
            fragmentShader: customPhongShader.fragmentShader,
            fog: false,
            lights: true
        })
        if(side == null){
            side = THREE.DoubleSide
        }
        material.side = side
        material.normalMap = new THREE.Texture() //hack parameters object to get the extension into fragment prefixes

        material.uniforms.detailmap.value.wrapS = THREE.RepeatWrapping
        material.uniforms.detailmap.value.wrapT = THREE.RepeatWrapping
        material.uniforms.detailmap.value.repeat.set(2,2)

        return material
    }
}
