var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev){
    return {
        dataModelVersion: '3.0.2',
        designData: mapDesignData(prev.designData)
    }
}

function mapDesignData(prev){
    var template = require('../getTemplate')(prev.designTemplate)
    return _.defaultsDeep(prev, template)
}
