var _ = require('lodash')
var sportId = require('../../config').sportId

module.exports = mapConfigData

function mapConfigData(prev){
    return {
        dataModelVersion: '4.0.0',
        designData: mapDesignData(prev.designData)
    }
}

function mapDesignData(prev){
  if(sportId === 'football') {
      return _.set(_.clone(prev), 'jerseyInside', _.cloneDeep(prev.jersey))
  }
  return prev
}
