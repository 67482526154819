module.exports={
    "USFLAG": {
        "name": "USFLAG",
        "code": "USFLAG",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 0,
        "sizeList": [
            "2"
        ],
        "icon": "assets/logos/icons/USFLAG.png?2014-09-30"
    },
    "7ON7": {
        "name": "7ON7",
        "code": "7ON7",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/7ON7.png?2014-09-30"
    },
    "BASEBALL1": {
        "name": "BASEBALL1",
        "code": "BASEBALL1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASEBALL1.png?2014-09-30"
    },
    "BASEBALL2": {
        "name": "BASEBALL2",
        "code": "BASEBALL2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASEBALL2.png?2014-09-30"
    },
    "BASEBALL3": {
        "name": "BASEBALL3",
        "code": "BASEBALL3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASEBALL3.png?2014-09-30"
    },
    "BASEBALL4": {
        "name": "BASEBALL4",
        "code": "BASEBALL4",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BASEBALL4.png?2014-09-30"
    },
    "BASEBALL5": {
        "name": "BASEBALL5",
        "code": "BASEBALL5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASEBALL5.png?2014-09-30"
    },
    "BASEBALL6": {
        "name": "BASEBALL6",
        "code": "BASEBALL6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASEBALL6.png?2014-09-30"
    },
    "BASKETBALL1": {
        "name": "BASKETBALL1",
        "code": "BASKETBALL1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASKETBALL1.png?2014-09-30"
    },
    "BASKETBALL2": {
        "name": "BASKETBALL2",
        "code": "BASKETBALL2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASKETBALL2.png?2014-09-30"
    },
    "BASKETBALL3": {
        "name": "BASKETBALL3",
        "code": "BASKETBALL3",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BASKETBALL3.png?2014-09-30"
    },
    "BASKETBALL4": {
        "name": "BASKETBALL4",
        "code": "BASKETBALL4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BASKETBALL4.png?2014-09-30"
    },
    "BEAR1": {
        "name": "BEAR1",
        "code": "BEAR1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BEAR1.png?2014-09-30"
    },
    "BEAR2": {
        "name": "BEAR2",
        "code": "BEAR2",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BEAR2.png?2014-09-30"
    },
    "BEAR3": {
        "name": "BEAR3",
        "code": "BEAR3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BEAR3.png?2014-09-30"
    },
    "BEAR4": {
        "name": "BEAR4",
        "code": "BEAR4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BEAR4.png?2014-09-30"
    },
    "BEAR5": {
        "name": "BEAR5",
        "code": "BEAR5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BEAR5.png?2014-09-30"
    },
    "BEAR6": {
        "name": "BEAR6",
        "code": "BEAR6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BEAR6.png?2014-09-30"
    },
    "BIRD1": {
        "name": "BIRD1",
        "code": "BIRD1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD1.png?2014-09-30"
    },
    "BIRD2": {
        "name": "BIRD2",
        "code": "BIRD2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD2.png?2014-09-30"
    },
    "BIRD3": {
        "name": "BIRD3",
        "code": "BIRD3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD3.png?2014-09-30"
    },
    "BIRD4": {
        "name": "BIRD4",
        "code": "BIRD4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD4.png?2014-09-30"
    },
    "BIRD5": {
        "name": "BIRD5",
        "code": "BIRD5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD5.png?2014-09-30"
    },
    "BIRD6": {
        "name": "BIRD6",
        "code": "BIRD6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD6.png?2014-09-30"
    },
    "BIRD7": {
        "name": "BIRD7",
        "code": "BIRD7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD7.png?2014-09-30"
    },
    "BIRD8": {
        "name": "BIRD8",
        "code": "BIRD8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD8.png?2014-09-30"
    },
    "BIRD9": {
        "name": "BIRD9",
        "code": "BIRD9",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD9.png?2014-09-30"
    },
    "BIRD10": {
        "name": "BIRD10",
        "code": "BIRD10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD10.png?2014-09-30"
    },
    "BIRD11": {
        "name": "BIRD11",
        "code": "BIRD11",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD11.png?2014-09-30"
    },
    "BIRD12": {
        "name": "BIRD12",
        "code": "BIRD12",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD12.png?2014-09-30"
    },
    "BIRD13": {
        "name": "BIRD13",
        "code": "BIRD13",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD13.png?2014-09-30"
    },
    "BIRD14": {
        "name": "BIRD14",
        "code": "BIRD14",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD14.png?2014-09-30"
    },
    "BIRD15": {
        "name": "BIRD15",
        "code": "BIRD15",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD15.png?2014-09-30"
    },
    "BIRD16": {
        "name": "BIRD16",
        "code": "BIRD16",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD16.png?2014-09-30"
    },
    "BIRD17": {
        "name": "BIRD17",
        "code": "BIRD17",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD17.png?2014-09-30"
    },
    "BIRD18": {
        "name": "BIRD18",
        "code": "BIRD18",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD18.png?2014-09-30"
    },
    "BIRD19": {
        "name": "BIRD19",
        "code": "BIRD19",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD19.png?2014-09-30"
    },
    "BIRD20": {
        "name": "BIRD20",
        "code": "BIRD20",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD20.png?2014-09-30"
    },
    "BIRD21": {
        "name": "BIRD21",
        "code": "BIRD21",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD21.png?2014-09-30"
    },
    "BIRD22": {
        "name": "BIRD22",
        "code": "BIRD22",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 3,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD22.png?2014-09-30"
    },
    "BIRD23": {
        "name": "BIRD23",
        "code": "BIRD23",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD23.png?2014-09-30"
    },
    "BIRD24": {
        "name": "BIRD24",
        "code": "BIRD24",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD24.png?2014-09-30"
    },
    "BIRD25": {
        "name": "BIRD25",
        "code": "BIRD25",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD25.png?2014-09-30"
    },
    "BIRD26": {
        "name": "BIRD26",
        "code": "BIRD26",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD26.png?2014-09-30"
    },
    "BIRD27": {
        "name": "BIRD27",
        "code": "BIRD27",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD27.png?2014-09-30"
    },
    "BIRD28": {
        "name": "BIRD28",
        "code": "BIRD28",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD28.png?2014-09-30"
    },
    "BIRD29": {
        "name": "BIRD29",
        "code": "BIRD29",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD29.png?2014-09-30"
    },
    "BIRD30": {
        "name": "BIRD30",
        "code": "BIRD30",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/BIRD30.png?2014-09-30"
    },
    "BRAVE1": {
        "name": "BRAVE1",
        "code": "BRAVE1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BRAVE1.png?2014-09-30"
    },
    "BRAVE2": {
        "name": "BRAVE2",
        "code": "BRAVE2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BRAVE2.png?2014-09-30"
    },
    "BUCCANEER": {
        "name": "BUCCANEER",
        "code": "BUCCANEER",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BUCCANEER.png?2014-09-30"
    },
    "BUFFALO": {
        "name": "BUFFALO",
        "code": "BUFFALO",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BUFFALO.png?2014-09-30"
    },
    "BULLDOG1": {
        "name": "BULLDOG1",
        "code": "BULLDOG1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG1.png?2014-09-30"
    },
    "BULLDOG2": {
        "name": "BULLDOG2",
        "code": "BULLDOG2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG2.png?2014-09-30"
    },
    "BULLDOG3": {
        "name": "BULLDOG3",
        "code": "BULLDOG3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG3.png?2014-09-30"
    },
    "BULLDOG4": {
        "name": "BULLDOG4",
        "code": "BULLDOG4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG4.png?2014-09-30"
    },
    "BULLDOG5": {
        "name": "BULLDOG5",
        "code": "BULLDOG5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG5.png?2014-09-30"
    },
    "BULLDOG6": {
        "name": "BULLDOG6",
        "code": "BULLDOG6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG6.png?2014-09-30"
    },
    "BULLDOG7": {
        "name": "BULLDOG7",
        "code": "BULLDOG7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG7.png?2014-09-30"
    },
    "BULLDOG8": {
        "name": "BULLDOG8",
        "code": "BULLDOG8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG8.png?2014-09-30"
    },
    "BULLDOG9": {
        "name": "BULLDOG9",
        "code": "BULLDOG9",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG9.png?2014-09-30"
    },
    "BULLDOG10": {
        "name": "BULLDOG10",
        "code": "BULLDOG10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/BULLDOG10.png?2014-09-30"
    },
    "CARDINAL1": {
        "name": "CARDINAL1",
        "code": "CARDINAL1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CARDINAL1.png?2014-09-30"
    },
    "CARDINAL2": {
        "name": "CARDINAL2",
        "code": "CARDINAL2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CARDINAL2.png?2014-09-30"
    },
    "CAT1": {
        "name": "CAT1",
        "code": "CAT1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT1.png?2014-09-30"
    },
    "CAT2": {
        "name": "CAT2",
        "code": "CAT2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT2.png?2014-09-30"
    },
    "CAT3": {
        "name": "CAT3",
        "code": "CAT3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT3.png?2014-09-30"
    },
    "CAT4": {
        "name": "CAT4",
        "code": "CAT4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT4.png?2014-09-30"
    },
    "CAT5": {
        "name": "CAT5",
        "code": "CAT5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT5.png?2014-09-30"
    },
    "CAT6": {
        "name": "CAT6",
        "code": "CAT6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT6.png?2014-09-30"
    },
    "CAT7": {
        "name": "CAT7",
        "code": "CAT7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT7.png?2014-09-30"
    },
    "CAT8": {
        "name": "CAT8",
        "code": "CAT8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT8.png?2014-09-30"
    },
    "CAT9": {
        "name": "CAT9",
        "code": "CAT9",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT9.png?2014-09-30"
    },
    "CAT10": {
        "name": "CAT10",
        "code": "CAT10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT10.png?2014-09-30"
    },
    "CAT11": {
        "name": "CAT11",
        "code": "CAT11",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT11.png?2014-09-30"
    },
    "CAT12": {
        "name": "CAT12",
        "code": "CAT12",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT12.png?2014-09-30"
    },
    "CAT13": {
        "name": "CAT13",
        "code": "CAT13",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT13.png?2014-09-30"
    },
    "CAT14": {
        "name": "CAT14",
        "code": "CAT14",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT14.png?2014-09-30"
    },
    "CAT15": {
        "name": "CAT15",
        "code": "CAT15",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT15.png?2014-09-30"
    },
    "CAT16": {
        "name": "CAT16",
        "code": "CAT16",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT16.png?2014-09-30"
    },
    "CAT17": {
        "name": "CAT17",
        "code": "CAT17",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT17.png?2014-09-30"
    },
    "CAT18": {
        "name": "CAT18",
        "code": "CAT18",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT18.png?2014-09-30"
    },
    "CAT19": {
        "name": "CAT19",
        "code": "CAT19",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAT19.png?2014-09-30"
    },
    "CAVALIER1": {
        "name": "CAVALIER1",
        "code": "CAVALIER1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAVALIER1.png?2014-09-30"
    },
    "CAVALIER2": {
        "name": "CAVALIER2",
        "code": "CAVALIER2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAVALIER2.png?2014-09-30"
    },
    "CAVALIER3": {
        "name": "CAVALIER3",
        "code": "CAVALIER3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CAVALIER3.png?2014-09-30"
    },
    "CHIEF": {
        "name": "CHIEF",
        "code": "CHIEF",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/CHIEF.png?2014-09-30"
    },
    "COBRA": {
        "name": "COBRA",
        "code": "COBRA",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/COBRA.png?2014-09-30"
    },
    "COMICTROJAN": {
        "name": "COMICTROJAN",
        "code": "COMICTROJAN",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/COMICTROJAN.png?2014-09-30"
    },
    "COUGAR": {
        "name": "COUGAR",
        "code": "COUGAR",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/COUGAR.png?2014-09-30"
    },
    "DEVIL": {
        "name": "DEVIL",
        "code": "DEVIL",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/DEVIL.png?2014-09-30"
    },
    "DEVIL2": {
        "name": "DEVIL2",
        "code": "DEVIL2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/DEVIL2.png?2014-09-30"
    },
    "DEVIL3": {
        "name": "DEVIL3",
        "code": "DEVIL3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/DEVIL3.png?2014-09-30"
    },
    "DEVIL4": {
        "name": "DEVIL4",
        "code": "DEVIL4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/DEVIL4.png?2014-09-30"
    },
    "DEVIL5": {
        "name": "DEVIL5",
        "code": "DEVIL5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/DEVIL5.png?2014-09-30"
    },
    "DRAGON": {
        "name": "DRAGON",
        "code": "DRAGON",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/DRAGON.png?2014-09-30"
    },
    "DRAGONH13": {
        "name": "DRAGONH13",
        "code": "DRAGONH13",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/DRAGONH13.png?2014-09-30"
    },
    "DRAGONI13": {
        "name": "DRAGONI13",
        "code": "DRAGONI13",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/DRAGONI13.png?2014-09-30"
    },
    "EAGLE1": {
        "name": "EAGLE1",
        "code": "EAGLE1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/EAGLE1.png?2014-09-30"
    },
    "EAGLE2": {
        "name": "EAGLE2",
        "code": "EAGLE2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/EAGLE2.png?2014-09-30"
    },
    "EAGLE3": {
        "name": "EAGLE3",
        "code": "EAGLE3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/EAGLE3.png?2014-09-30"
    },
    "EAGLE4": {
        "name": "EAGLE4",
        "code": "EAGLE4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/EAGLE4.png?2014-09-30"
    },
    "EAGLE5": {
        "name": "EAGLE5",
        "code": "EAGLE5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/EAGLE5.png?2014-09-30"
    },
    "FOOTBALL1": {
        "name": "FOOTBALL1",
        "code": "FOOTBALL1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/FOOTBALL1.png?2014-09-30"
    },
    "FOOTBALL2": {
        "name": "FOOTBALL2",
        "code": "FOOTBALL2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/FOOTBALL2.png?2014-09-30"
    },
    "FOOTBALL3": {
        "name": "FOOTBALL3",
        "code": "FOOTBALL3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/FOOTBALL3.png?2014-09-30"
    },
    "FOOTBALL4": {
        "name": "FOOTBALL4",
        "code": "FOOTBALL4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/FOOTBALL4.png?2014-09-30"
    },
    "FOOTBALL5": {
        "name": "FOOTBALL5",
        "code": "FOOTBALL5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/FOOTBALL5.png?2014-09-30"
    },
    "HELMET": {
        "name": "HELMET",
        "code": "HELMET",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HELMET.png?2014-09-30"
    },
    "HORSE1": {
        "name": "HORSE1",
        "code": "HORSE1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE1.png?2014-09-30"
    },
    "HORSE10": {
        "name": "HORSE10",
        "code": "HORSE10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE10.png?2014-09-30"
    },
    "HORSE2": {
        "name": "HORSE2",
        "code": "HORSE2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE2.png?2014-09-30"
    },
    "HORSE3": {
        "name": "HORSE3",
        "code": "HORSE3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE3.png?2014-09-30"
    },
    "HORSE4": {
        "name": "HORSE4",
        "code": "HORSE4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE4.png?2014-09-30"
    },
    "HORSE5": {
        "name": "HORSE5",
        "code": "HORSE5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE5.png?2014-09-30"
    },
    "HORSE6": {
        "name": "HORSE6",
        "code": "HORSE6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE6.png?2014-09-30"
    },
    "HORSE7": {
        "name": "HORSE7",
        "code": "HORSE7",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE7.png?2014-09-30"
    },
    "HORSE8": {
        "name": "HORSE8",
        "code": "HORSE8",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE8.png?2014-09-30"
    },
    "HORSE9": {
        "name": "HORSE9",
        "code": "HORSE9",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/HORSE9.png?2014-09-30"
    },
    "HUSKYF1": {
        "name": "HUSKYF1",
        "code": "HUSKYF1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/HUSKYF1.png?2014-09-30"
    },
    "INDIAN1": {
        "name": "INDIAN1",
        "code": "INDIAN1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN1.png?2014-09-30"
    },
    "INDIAN2": {
        "name": "INDIAN2",
        "code": "INDIAN2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN2.png?2014-09-30"
    },
    "INDIAN3": {
        "name": "INDIAN3",
        "code": "INDIAN3",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN3.png?2014-09-30"
    },
    "INDIAN4": {
        "name": "INDIAN4",
        "code": "INDIAN4",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN4.png?2014-09-30"
    },
    "INDIAN5": {
        "name": "INDIAN5",
        "code": "INDIAN5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN5.png?2014-09-30"
    },
    "INDIAN6": {
        "name": "INDIAN6",
        "code": "INDIAN6",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN6.png?2014-09-30"
    },
    "INDIAN7": {
        "name": "INDIAN7",
        "code": "INDIAN7",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN7.png?2014-09-30"
    },
    "INDIAN8": {
        "name": "INDIAN8",
        "code": "INDIAN8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN8.png?2014-09-30"
    },
    "INDIAN9": {
        "name": "INDIAN9",
        "code": "INDIAN9",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN9.png?2014-09-30"
    },
    "INDIAN10": {
        "name": "INDIAN10",
        "code": "INDIAN10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN10.png?2014-09-30"
    },
    "INDIAN11": {
        "name": "INDIAN11",
        "code": "INDIAN11",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN11.png?2014-09-30"
    },
    "INDIAN12": {
        "name": "INDIAN12",
        "code": "INDIAN12",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN12.png?2014-09-30"
    },
    "INDIAN13": {
        "name": "INDIAN13",
        "code": "INDIAN13",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN13.png?2014-09-30"
    },
    "INDIAN14": {
        "name": "INDIAN14",
        "code": "INDIAN14",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN14.png?2014-09-30"
    },
    "INDIAN15": {
        "name": "INDIAN15",
        "code": "INDIAN15",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INDIAN15.png?2014-09-30"
    },
    "INSECT1": {
        "name": "INSECT1",
        "code": "INSECT1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INSECT1.png?2014-09-30"
    },
    "INSECT2": {
        "name": "INSECT2",
        "code": "INSECT2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INSECT2.png?2014-09-30"
    },
    "INSECT3": {
        "name": "INSECT3",
        "code": "INSECT3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INSECT3.png?2014-09-30"
    },
    "INSECT4": {
        "name": "INSECT4",
        "code": "INSECT4",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/INSECT4.png?2014-09-30"
    },
    "INSECT5": {
        "name": "INSECT5",
        "code": "INSECT5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/INSECT5.png?2014-09-30"
    },
    "KNIGHT1": {
        "name": "KNIGHT1",
        "code": "KNIGHT1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT1.png?2014-09-30"
    },
    "KNIGHT2": {
        "name": "KNIGHT2",
        "code": "KNIGHT2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT2.png?2014-09-30"
    },
    "KNIGHT3": {
        "name": "KNIGHT3",
        "code": "KNIGHT3",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT3.png?2014-09-30"
    },
    "KNIGHT4": {
        "name": "KNIGHT4",
        "code": "KNIGHT4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT4.png?2014-09-30"
    },
    "KNIGHT5": {
        "name": "KNIGHT5",
        "code": "KNIGHT5",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT5.png?2014-09-30"
    },
    "KNIGHT6": {
        "name": "KNIGHT6",
        "code": "KNIGHT6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT6.png?2014-09-30"
    },
    "KNIGHT7": {
        "name": "KNIGHT7",
        "code": "KNIGHT7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT7.png?2014-09-30"
    },
    "KNIGHT8": {
        "name": "KNIGHT8",
        "code": "KNIGHT8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT8.png?2014-09-30"
    },
    "KNIGHT9": {
        "name": "KNIGHT9",
        "code": "KNIGHT9",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT9.png?2014-09-30"
    },
    "KNIGHT10": {
        "name": "KNIGHT10",
        "code": "KNIGHT10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/KNIGHT10.png?2014-09-30"
    },
    "LIGHTNING1": {
        "name": "LIGHTNING1",
        "code": "LIGHTNING1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LIGHTNING1.png?2014-09-30"
    },
    "LIGHTNING2": {
        "name": "LIGHTNING2",
        "code": "LIGHTNING2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LIGHTNING2.png?2014-09-30"
    },
    "LIGHTNING3": {
        "name": "LIGHTNING3",
        "code": "LIGHTNING3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LIGHTNING3.png?2014-09-30"
    },
    "LIGHTNING4": {
        "name": "LIGHTNING4",
        "code": "LIGHTNING4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LIGHTNING4.png?2014-09-30"
    },
    "LION1": {
        "name": "LION1",
        "code": "LION1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION1.png?2014-09-30"
    },
    "LION2": {
        "name": "LION2",
        "code": "LION2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION2.png?2014-09-30"
    },
    "LION3": {
        "name": "LION3",
        "code": "LION3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION3.png?2014-09-30"
    },
    "LION4": {
        "name": "LION4",
        "code": "LION4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION4.png?2014-09-30"
    },
    "LION5": {
        "name": "LION5",
        "code": "LION5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION5.png?2014-09-30"
    },
    "LION6": {
        "name": "LION6",
        "code": "LION6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION6.png?2014-09-30"
    },
    "LION7": {
        "name": "LION7",
        "code": "LION7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION7.png?2014-09-30"
    },
    "LION8": {
        "name": "LION8",
        "code": "LION8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LION8.png?2014-09-30"
    },
    "LONGHORNS": {
        "name": "LONGHORNS",
        "code": "LONGHORNS",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/LONGHORNS.png?2014-09-30"
    },
    "MARLIN": {
        "name": "MARLIN",
        "code": "MARLIN",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MARLIN.png?2014-09-30"
    },
    "MISC1": {
        "name": "MISC1",
        "code": "MISC1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC1.png?2014-09-30"
    },
    "MISC2": {
        "name": "MISC2",
        "code": "MISC2",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC2.png?2014-09-30"
    },
    "MISC3": {
        "name": "MISC3",
        "code": "MISC3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC3.png?2014-09-30"
    },
    "MISC4": {
        "name": "MISC4",
        "code": "MISC4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC4.png?2014-09-30"
    },
    "MISC5": {
        "name": "MISC5",
        "code": "MISC5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC5.png?2014-09-30"
    },
    "MISC6": {
        "name": "MISC6",
        "code": "MISC6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC6.png?2014-09-30"
    },
    "MISC7": {
        "name": "MISC7",
        "code": "MISC7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC7.png?2014-09-30"
    },
    "MISC8": {
        "name": "MISC8",
        "code": "MISC8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC8.png?2014-09-30"
    },
    "MISC9": {
        "name": "MISC9",
        "code": "MISC9",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC9.png?2014-09-30"
    },
    "MISC10": {
        "name": "MISC10",
        "code": "MISC10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC10.png?2014-09-30"
    },
    "MISC11": {
        "name": "MISC11",
        "code": "MISC11",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC11.png?2014-09-30"
    },
    "MISC12": {
        "name": "MISC12",
        "code": "MISC12",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC12.png?2014-09-30"
    },
    "MISC13": {
        "name": "MISC13",
        "code": "MISC13",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC13.png?2014-09-30"
    },
    "MISC14": {
        "name": "MISC14",
        "code": "MISC14",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC14.png?2014-09-30"
    },
    "MISC15": {
        "name": "MISC15",
        "code": "MISC15",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC15.png?2014-09-30"
    },
    "MISC16": {
        "name": "MISC16",
        "code": "MISC16",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC16.png?2014-09-30"
    },
    "MISC17": {
        "name": "MISC17",
        "code": "MISC17",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC17.png?2014-09-30"
    },
    "MISC18": {
        "name": "MISC18",
        "code": "MISC18",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC18.png?2014-09-30"
    },
    "MISC19": {
        "name": "MISC19",
        "code": "MISC19",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC19.png?2014-09-30"
    },
    "MISC20": {
        "name": "MISC20",
        "code": "MISC20",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC20.png?2014-09-30"
    },
    "MISC21": {
        "name": "MISC21",
        "code": "MISC21",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC21.png?2014-09-30"
    },
    "MISC22": {
        "name": "MISC22",
        "code": "MISC22",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC22.png?2014-09-30"
    },
    "MISC23": {
        "name": "MISC23",
        "code": "MISC23",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/MISC23.png?2014-09-30"
    },
    "MUSTANG1": {
        "name": "MUSTANG1",
        "code": "MUSTANG1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MUSTANG1.png?2014-09-30"
    },
    "MUSTANG2": {
        "name": "MUSTANG2",
        "code": "MUSTANG2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/MUSTANG2.png?2014-09-30"
    },
    "PANTHER1": {
        "name": "PANTHER1",
        "code": "PANTHER1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PANTHER1.png?2014-09-30"
    },
    "PANTHER2": {
        "name": "PANTHER2",
        "code": "PANTHER2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PANTHER2.png?2014-09-30"
    },
    "PAW1": {
        "name": "PAW1",
        "code": "PAW1",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW1.png?2014-09-30"
    },
    "PAW2": {
        "name": "PAW2",
        "code": "PAW2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW2.png?2014-09-30"
    },
    "PAW3": {
        "name": "PAW3",
        "code": "PAW3",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW3.png?2014-09-30"
    },
    "PAW4": {
        "name": "PAW4",
        "code": "PAW4",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW4.png?2014-09-30"
    },
    "PAW5": {
        "name": "PAW5",
        "code": "PAW5",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW5.png?2014-09-30"
    },
    "PAW6": {
        "name": "PAW6",
        "code": "PAW6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW6.png?2014-09-30"
    },
    "PAW7": {
        "name": "PAW7",
        "code": "PAW7",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW7.png?2014-09-30"
    },
    "PAW8": {
        "name": "PAW8",
        "code": "PAW8",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW8.png?2014-09-30"
    },
    "PAW9": {
        "name": "PAW9",
        "code": "PAW9",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW9.png?2014-09-30"
    },
    "PAW10": {
        "name": "PAW10",
        "code": "PAW10",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW10.png?2014-09-30"
    },
    "PAW11": {
        "name": "PAW11",
        "code": "PAW11",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW11.png?2014-09-30"
    },
    "PAW12": {
        "name": "PAW12",
        "code": "PAW12",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW12.png?2014-09-30"
    },
    "PAW13": {
        "name": "PAW13",
        "code": "PAW13",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW13.png?2014-09-30"
    },
    "PAW14": {
        "name": "PAW14",
        "code": "PAW14",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW14.png?2014-09-30"
    },
    "PAW15": {
        "name": "PAW15",
        "code": "PAW15",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW15.png?2014-09-30"
    },
    "PAW16": {
        "name": "PAW16",
        "code": "PAW16",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW16.png?2014-09-30"
    },
    "PAW17": {
        "name": "PAW17",
        "code": "PAW17",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW17.png?2014-09-30"
    },
    "PAW18": {
        "name": "PAW18",
        "code": "PAW18",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PAW18.png?2014-09-30"
    },
    "PIRATE1": {
        "name": "PIRATE1",
        "code": "PIRATE1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE1.png?2014-09-30"
    },
    "PIRATE2": {
        "name": "PIRATE2",
        "code": "PIRATE2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE2.png?2014-09-30"
    },
    "PIRATE3": {
        "name": "PIRATE3",
        "code": "PIRATE3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE3.png?2014-09-30"
    },
    "PIRATE4": {
        "name": "PIRATE4",
        "code": "PIRATE4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE4.png?2014-09-30"
    },
    "PIRATE5": {
        "name": "PIRATE5",
        "code": "PIRATE5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE5.png?2014-09-30"
    },
    "PIRATE6": {
        "name": "PIRATE6",
        "code": "PIRATE6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE6.png?2014-09-30"
    },
    "PIRATE7": {
        "name": "PIRATE7",
        "code": "PIRATE7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE7.png?2014-09-30"
    },
    "PIRATE8": {
        "name": "PIRATE8",
        "code": "PIRATE8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE8.png?2014-09-30"
    },
    "PIRATE9": {
        "name": "PIRATE9",
        "code": "PIRATE9",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE9.png?2014-09-30"
    },
    "PIRATE10": {
        "name": "PIRATE10",
        "code": "PIRATE10",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE10.png?2014-09-30"
    },
    "PIRATE11": {
        "name": "PIRATE11",
        "code": "PIRATE11",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE11.png?2014-09-30"
    },
    "PIRATE12": {
        "name": "PIRATE12",
        "code": "PIRATE12",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE12.png?2014-09-30"
    },
    "PIRATE13": {
        "name": "PIRATE13",
        "code": "PIRATE13",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/PIRATE13.png?2014-09-30"
    },
    "RAM1": {
        "name": "RAM1",
        "code": "RAM1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/RAM1.png?2014-09-30"
    },
    "RAM2": {
        "name": "RAM2",
        "code": "RAM2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/RAM2.png?2014-09-30"
    },
    "REBEL": {
        "name": "REBEL",
        "code": "REBEL",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/REBEL.png?2014-09-30"
    },
    "SHAMROCK1": {
        "name": "SHAMROCK1",
        "code": "SHAMROCK1",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SHAMROCK1.png?2014-09-30"
    },
    "SHAMROCK2": {
        "name": "SHAMROCK2",
        "code": "SHAMROCK2",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SHAMROCK2.png?2014-09-30"
    },
    "SHARK1": {
        "name": "SHARK1",
        "code": "SHARK1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SHARK1.png?2014-09-30"
    },
    "SNAKE1": {
        "name": "SNAKE1",
        "code": "SNAKE1",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SNAKE1.png?2014-09-30"
    },
    "SNAKE2": {
        "name": "SNAKE2",
        "code": "SNAKE2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SNAKE2.png?2014-09-30"
    },
    "SNAKE3": {
        "name": "SNAKE3",
        "code": "SNAKE3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SNAKE3.png?2014-09-30"
    },
    "SPARTAN1": {
        "name": "SPARTAN1",
        "code": "SPARTAN1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN1.png?2014-09-30"
    },
    "SPARTAN2": {
        "name": "SPARTAN2",
        "code": "SPARTAN2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN2.png?2014-09-30"
    },
    "SPARTAN3": {
        "name": "SPARTAN3",
        "code": "SPARTAN3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN3.png?2014-09-30"
    },
    "SPARTAN4": {
        "name": "SPARTAN4",
        "code": "SPARTAN4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN4.png?2014-09-30"
    },
    "SPARTAN5": {
        "name": "SPARTAN5",
        "code": "SPARTAN5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN5.png?2014-09-30"
    },
    "SPARTAN6": {
        "name": "SPARTAN6",
        "code": "SPARTAN6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN6.png?2014-09-30"
    },
    "SPARTAN7": {
        "name": "SPARTAN7",
        "code": "SPARTAN7",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN7.png?2014-09-30"
    },
    "SPARTAN8": {
        "name": "SPARTAN8",
        "code": "SPARTAN8",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN8.png?2014-09-30"
    },
    "SPARTAN9": {
        "name": "SPARTAN9",
        "code": "SPARTAN9",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN9.png?2014-09-30"
    },
    "SPARTAN10": {
        "name": "SPARTAN10",
        "code": "SPARTAN10",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN10.png?2014-09-30"
    },
    "SPARTAN11": {
        "name": "SPARTAN11",
        "code": "SPARTAN11",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN11.png?2014-09-30"
    },
    "SPARTAN12": {
        "name": "SPARTAN12",
        "code": "SPARTAN12",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN12.png?2014-09-30"
    },
    "SPARTAN13": {
        "name": "SPARTAN13",
        "code": "SPARTAN13",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN13.png?2014-09-30"
    },
    "SPARTAN14": {
        "name": "SPARTAN14",
        "code": "SPARTAN14",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/SPARTAN14.png?2014-09-30"
    },
    "STAR1": {
        "name": "STAR1",
        "code": "STAR1",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/STAR1.png?2014-09-30"
    },
    "STAR2": {
        "name": "STAR2",
        "code": "STAR2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/STAR2.png?2014-09-30"
    },
    "STAR3": {
        "name": "STAR3",
        "code": "STAR3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/STAR3.png?2014-09-30"
    },
    "STAR4": {
        "name": "STAR4",
        "code": "STAR4",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/STAR4.png?2014-09-30"
    },
    "STAR5": {
        "name": "STAR5",
        "code": "STAR5",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/STAR5.png?2014-09-30"
    },
    "TIGER1": {
        "name": "TIGER1",
        "code": "TIGER1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/TIGER1.png?2014-09-30"
    },
    "TIGER2": {
        "name": "TIGER2",
        "code": "TIGER2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/TIGER2.png?2014-09-30"
    },
    "TIGER3": {
        "name": "TIGER3",
        "code": "TIGER3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/TIGER3.png?2014-09-30"
    },
    "VIKING1": {
        "name": "VIKING1",
        "code": "VIKING1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/VIKING1.png?2014-09-30"
    },
    "VIKING2": {
        "name": "VIKING2",
        "code": "VIKING2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/VIKING2.png?2014-09-30"
    },
    "VIKING3": {
        "name": "VIKING3",
        "code": "VIKING3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/VIKING3.png?2014-09-30"
    },
    "VIKING4": {
        "name": "VIKING4",
        "code": "VIKING4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/VIKING4.png?2014-09-30"
    },
    "VIKING5": {
        "name": "VIKING5",
        "code": "VIKING5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/VIKING5.png?2014-09-30"
    },
    "VIKING6": {
        "name": "VIKING6",
        "code": "VIKING6",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/VIKING6.png?2014-09-30"
    },
    "WILDCAT1": {
        "name": "WILDCAT1",
        "code": "WILDCAT1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WILDCAT1.png?2014-09-30"
    },
    "WILDCAT2": {
        "name": "WILDCAT2",
        "code": "WILDCAT2",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WILDCAT2.png?2014-09-30"
    },
    "WILDCAT3": {
        "name": "WILDCAT3",
        "code": "WILDCAT3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WILDCAT3.png?2014-09-30"
    },
    "WOLF": {
        "name": "WOLF",
        "code": "WOLF",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WOLF.png?2014-09-30"
    },
    "WOLF1": {
        "name": "WOLF1",
        "code": "WOLF1",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WOLF1.png?2014-09-30"
    },
    "WOLF2": {
        "name": "WOLF2",
        "code": "WOLF2",
        "abbr": null,
        "subsets": {
            "watermark": true
        },
        "colorCount": 1,
        "sizeList": [],
        "icon": "assets/logos/icons/WOLF2.png?2014-09-30"
    },
    "WOLF3": {
        "name": "WOLF3",
        "code": "WOLF3",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WOLF3.png?2014-09-30"
    },
    "WOLF4": {
        "name": "WOLF4",
        "code": "WOLF4",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WOLF4.png?2014-09-30"
    },
    "WOLF5": {
        "name": "WOLF5",
        "code": "WOLF5",
        "abbr": null,
        "subsets": {
            "watermark": false
        },
        "colorCount": 2,
        "sizeList": [],
        "icon": "assets/logos/icons/WOLF5.png?2014-09-30"
    }
}