var _ = require('lodash')
var sportId = require('../config').sportId

var BASEBALL = sportId === 'baseball'
var SOFTBALL = sportId === 'softball'
var FOOTBALL = sportId === 'football'

var C = {
    football: ['W', 'S', 'B', 'W', 'S', 'B'],
    softball: ['N', 'GR', 'BG', 'W', 'N', 'W', 'B', 'N', 'GR', 'BG'],
    baseball: ['N', 'W', 'S', 'W', 'B', 'S', 'N', 'W', 'S'],
}[sportId]

var i = 0
function getColorIndex(color) {
    return C.indexOf(color) || 0
}

function areaFillByIndex(index) {
    return {
        pattern: 'solidColor',
        fabric: SOFTBALL ? 'none' : undefined,
        color_1: C[(i = index)],
        color_2: C[++i],
        color_3: C[++i],
        color_4: C[++i],
    }
}

function areaFillByColor(color) {
    return areaFillByIndex(getColorIndex(color))
}

function opData(type) {
    return {
        type: type,
        color_1: 'S',
        color_2: 'B',
        color_3: 'W',
    }
}

function valueBbSbFb(bb, sb, fb) {
    if (sportId === 'football') {
        return fb
    }
    if (sportId === 'softball') {
        return sb
    }
    return bb
}

var teamnamePlayerNameBase = {
    layout: 'straight',
    font: valueBbSbFb('anaheim', 'arizona', 'fullBlock'),
    fill: areaFillByColor(valueBbSbFb('S', 'N', 'B')),
    outline_1: valueBbSbFb('N', 'W', 'none'),
    outline_2: valueBbSbFb('none', 'GR', 'none'),
}

var numberBase = {
    text: valueBbSbFb('42', '5', '55'),
    font: valueBbSbFb('anaheimNumber', 'arizona47', 'fullBlockNumber'),
    fill: areaFillByColor(valueBbSbFb('W', 'W', 'S')),
    outline_1: valueBbSbFb('N', 'N', 'B'),
    outline_2: 'none',
}

var stockLogoBase = {
    design: 'USFLAG',
    fill: {
        color_1: 'W',
        color_2: 'B',
        color_3: 'S',
    },
}

function getFootballJersey(templateID) {
    return {
        model: templateID || 'PJDIV47',
        colorAreas: {
            area1: areaFillByIndex(0),
            area2: areaFillByIndex(0),
            area3: areaFillByIndex(0),
            area4: areaFillByIndex(0),
            area5: areaFillByIndex(2),
            area6: areaFillByIndex(2),
            area7: areaFillByIndex(1),
            area8: areaFillByIndex(0),
            area9: areaFillByIndex(1),
            area10: areaFillByIndex(2),
        },
        options: {
            logoColor: 'B',
            CLSU: {
                type: 'none',
                color_1: 'S',
                color_2: 'B',
                color_3: 'S',
            },
            sleeveStripes: {
                type: 'none',
                color_1: 'S',
                color_2: 'B',
            },
        },
        decoration: {
            type: 'PP',
            teamName: _.defaultsDeep({}, teamnamePlayerNameBase, {
                text: 'Diversity',
                size: '1',
                placement: '0',
                tailing: {
                  enabled: 'off',
                  text: 'Tailing',
                  font: 'fullBlock',
                  size: '1',
                  fill: 'W',
                  outline: 'none',
              },
            }),
            playerName: _.defaultsDeep({}, teamnamePlayerNameBase, {
                text: 'Playername',
                size: '2.5',
                placement: '14',
            }),
            number: {
                front: _.defaultsDeep({}, numberBase, {
                    placement: '5',
                    size: '8',
                }),
                back: _.defaultsDeep({}, numberBase, {
                    placement: '30',
                    size: '10',
                }),
                sleeve: _.defaultsDeep(
                    {
                        fill: areaFillByColor('B'),
                        outline_1: 'none',
                    },
                    numberBase,
                    {
                        placement: '18LR',
                        size: '3',
                    }
                ),
            },
            logo: {
                stock: _.defaultsDeep({}, stockLogoBase, {
                    placement: 'none',
                    size: '3.5',
                }),
                custom: {
                    customFile: '',
                    placement: 'none',
                    size: '4',
                },
            },
        },
    }
}

function getFootballPant(templateID) {
    return {
        model: templateID || 'PRO150',
        colorAreas: {
            area1: areaFillByIndex(0),
            area2: areaFillByIndex(0),
            area3: areaFillByIndex(0),
            area4: areaFillByIndex(0),
            area5: areaFillByIndex(0),
        },
        options: {
            beltColor: 'GR',
            logoColor: 'B',
        },
        decoration: {
            type: 'SBPT',
            teamName: _.defaultsDeep({}, teamnamePlayerNameBase, {
                text: 'Diversity',
                size: '1.5',
                placement: '27LR',
            }),
            logo: {
                stock: _.defaultsDeep({}, stockLogoBase, {
                    placement: 'none',
                    size: '2',
                }),
                custom: {
                    customFile: '',
                    placement: 'none',
                    size: '2',
                },
            },
            upperLogo: {
                stock: _.defaultsDeep({}, stockLogoBase, {
                    placement: 'none',
                    size: '1.5',
                }),
                custom: {
                    customFile: '',
                    placement: 'none',
                    size: '1.5',
                },
            },
        },
    }
}

function getBbSbJersey(templateID) {
    return {
        model: templateID || 'HOUSJ',
        colorAreas: {
            area1: areaFillByIndex(0),
            area2: areaFillByIndex(1),
            area3: areaFillByIndex(2),
            area4: areaFillByIndex(3), // the same color as for previous area
            area5: areaFillByIndex(3),
            area6: areaFillByIndex(4),
            area7: areaFillByIndex(5),
        },
        decoration: {
            type: 'fullSublimation',
            teamName: _.defaultsDeep({}, teamnamePlayerNameBase, {
                text: valueBbSbFb('spartans', 'Bandits'),
                size: '4',
                placement: '2',
                tailing: {
                    enabled: 'on',
                    text: 'Tailing',
                    font: 'fullBlock',
                    size: '1',
                    fill: 'W',
                    outline: 'none',
                },
            }),
            playerName: _.defaultsDeep({}, teamnamePlayerNameBase, {
                text: 'Player Name',
                size: '2',
                placement: 'none',
            }),
            number: {
                front: _.defaultsDeep({}, numberBase, {
                    placement: valueBbSbFb('none', '6'),
                    size: '4',
                }),
                back: _.defaultsDeep({}, numberBase, {
                    placement: valueBbSbFb('30', 'none'),
                    size: '8',
                }),
                sleeve: _.defaultsDeep({}, numberBase, {
                    placement: 'none',
                    size: '4',
                }),
            },
            logo: {
                stock: _.defaultsDeep({}, stockLogoBase, {
                    placement: valueBbSbFb('14', 'none'),
                    size: valueBbSbFb('2', '4'),
                }),
                custom: {
                    customFile: '',
                    placement: 'none',
                    size: '4',
                },
            },
            watermarkText: {
                text: 'Watermark',
                font: 'fullBlock',
                placement: 'none',
                layout: 'straight',
                size: '8',
            },
            watermarkLogo: {
                stock: {
                    design: 'none',
                    placement: 'none',
                    size: '8',
                },
                custom: {
                    customFile: '',
                    placement: 'none',
                    size: '8',
                },
            },
        },
        options: {
            OP1: opData('none'),
            OP2: opData('none'),
            OP2_5: BASEBALL && opData('none'),
            OP3: opData('none'),
            ribKnitCollar: opData('1inch1color'),
            ribKnitCuff: opData('none'),
            sublimatedCollar: opData('1inch1color'),
            sublimatedCuff: opData('none'),
            buttonColor: 'B',
            logoColor: valueBbSbFb('S', 'B', 'B'),
        },
    }
}

function getBbSbPant(templateID) {
    return {
        model: templateID || 'PRO150',
        colorAreas: {
            area1: areaFillByColor('B'),
            area2: areaFillByColor('W'),
            area3: areaFillByColor('B'),
            area4: areaFillByColor('B'),
            area5: areaFillByColor('B'),
        },
        decoration: {
            type: 'SBPT',
            teamName:
                SOFTBALL &&
                _.defaultsDeep({}, teamnamePlayerNameBase, {
                    text: 'Diversity',
                    size: '1.5',
                    placement: 'none',
                }),
            logo: SOFTBALL && {
                stock: _.defaultsDeep({}, stockLogoBase, {
                    placement: 'none',
                    size: '2',
                }),
                custom: {
                    customFile: '',
                    placement: 'none',
                    size: '2',
                },
            },
        },
        options: {
            OP4: opData('none'),
            OP4_2: SOFTBALL && opData('none'),
            OP4B: BASEBALL && opData('none'),
            OP4C: BASEBALL && opData('none'),
            OP15: opData('none'),
            RIBWST: opData('none'),
            logoColor: valueBbSbFb('S', 'B', 'B'),
        },
    }
}

var getBbSbTemplate = function(templateID) {
    console.log('getBbSbJersey(templateID)', getBbSbJersey(templateID))
    return {
        clothingType: 'jersey',
        designTemplate: templateID || 'HOUSJ',
        filter: {
            clothingType: 'none',
            clothingCollection: 'none',
            clothingCutType: 'none',
        },
        jersey: getBbSbJersey(templateID),
        pant: getBbSbPant(templateID),
    }
}

var getFootballTemplate = function(templateID) {
    return {
        clothingType: 'jersey',
        designTemplate: templateID || 'PJDIV47',
        filter: {
            clothingType: 'none',
            clothingQuality: 'none',
            clothingCollection: 'none',
            clothingCutType: 'none',
            clothingFitType: 'none',
        },
        isReversed: 'off',
        jersey: getFootballJersey(templateID),
        jerseyInside: _.omit(getFootballJersey(templateID), [
            'model',
            'colorAreas',
        ]),
        pant: getFootballPant(templateID),
    }
}

module.exports = function(templateID) {
    var designDataTemplate =
        sportId === 'football'
            ? getFootballTemplate(templateID)
            : getBbSbTemplate(templateID)
    var designData = _.cloneDeep(designDataTemplate)
    return designData
}
