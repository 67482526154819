var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev){
    return {
        dataModelVersion: '3.0.3',
        designData: mapDesignData(prev.designData)
    }
}

function mapDesignData(prev){
    return _.defaultsDeep({
      designTemplate: renameTemplate(prev.designTemplate),
      jersey: {
          model: renameTemplate(prev.jersey.model),
      },
      pant: {
          model: renameTemplate(prev.pant.model),
      },
    }, prev)
}

function renameTemplate(prev) {
  return prev
    .replace('WB150', 'WRB150')
    .replace('WJ150', 'WRJ150')
    .replace('ZONEJ-SS', 'WHOUSJ')
}
