var _ = require('lodash')

module.exports = function ($rootScope, $timeout, $window, $document, consoleService, $q, safeApply) {
    function browserSupportsWebGL() {
        // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/webgl.js
        var canvas = document.createElement('canvas')
        var supports = 'probablySupportsContext' in canvas ? 'probablySupportsContext' :  'supportsContext'
        if (supports in canvas) {
            return canvas[supports]('webgl') || canvas[supports]('experimental-webgl')
        }
        return 'WebGLRenderingContext' in window
    }

    var FallbackAdapter = function FallbackAdapter(options) {
        this.name = 'fallback'
        this.options = _.cloneDeep(options)
        this.isInitialized = false
    }

    FallbackAdapter.prototype.supported = function () {
        return true
    }

    FallbackAdapter.prototype.initialize = function (scope, callbacks) {
        this.isInitialized = true

        safeApply(scope, function () {
            callbacks.onInitialized && callbacks.onInitialized()
            callbacks.onRendered && callbacks.onRendered(true)
            scope.browserSupportsWebGL = browserSupportsWebGL()
            var eventMessage = scope.browserSupportsWebGL ? 'ContextUnavailable' : 'ContextNotSupported'
            consoleService.logEvent('WebGL', eventMessage)
        })
    }

    FallbackAdapter.prototype.changeActiveView = function () {}

    FallbackAdapter.prototype.update = function () {}

    FallbackAdapter.prototype.getSnapshots = function () {
        return $q.reject('Snapshots unavailable')
    }

    return FallbackAdapter
}
