var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev){
    var prevNumber = prev.designData.jersey.number
    var next = _.cloneDeep(prev)

    next.dataModelVersion = '2.0.1'
    next.designData.jersey.number = {
        text: prev.text,
        front: _.omit(_.assign({}, prevNumber, prevNumber.front), 'front', 'back', 'text'),
        back: _.omit(_.assign({}, prevNumber, prevNumber.back), 'front', 'back', 'text'),
    }

    return next
}
