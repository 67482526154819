var _ = require('lodash')

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.9.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    return _.defaultsDeep({
        jersey: mapGarment(prev.jersey),
        jerseyInside: mapGarment(prev.jerseyInside),
        pant: mapGarment(prev.pant),
    }, prev)
}

function mapGarment(prev) {
    if(!prev) {
      return prev
    }
    return {
        decoration: _.mapValues(prev.decoration, mapDeco)
    }
}

function mapDeco(prev, decoName) {
  if(!_.includes(['logo', 'upperLogo', 'watermarkLogo'], decoName)) {
    return prev
  }
  return {
    custom: {
      customFile: mapUrl(prev.custom.customFile),
    }
  }
}

function mapUrl(prev) {
  if(!prev) {
    return prev
  }
  return prev.replace('media.x.make.builders', 'customizer-media.orangelv.com')
}
