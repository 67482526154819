var Q = require('q')
var ObjLoader = require('./ObjLoader')
var THREE = require('three.js-xdomain')

var oldContent
var currentContent

function tryDisposeOldContent(){
    if(!oldContent) {
        return // initially the container is empty
    }
    oldContent.traverse(function (child) {
        if ( child instanceof THREE.Mesh ) {
            //child.material.dispose()
            child.geometry.dispose()
        }
    })
    oldContent = null
}

module.exports = function getModelLoader(){
    var deferred = null
    var objLoader = new ObjLoader()

    return {

        load: function(url){
            var _deferred = deferred = Q.defer()

            tryDisposeOldContent()
            oldContent = currentContent
            currentContent = null

            objLoader.load(
                url,
                function(obj){
                    if(!deferred || _deferred !== deferred){ return }
                    currentContent = obj

                    deferred && (_deferred === deferred) && deferred.resolve(currentContent)
                    deferred = null
                },
                undefined,
                function(errorEvent){
                    if(!deferred || _deferred !== deferred){ return }
                    deferred && deferred.reject(new Error('Failed to load model ' + url + ' with error ' + errorEvent))
                    deferred = null
                }
            )
            return deferred.promise
        },
        cancel: function(){
            deferred && deferred.reject('Cancelled')
            deferred = null
        }
    }
}
