var _ = require('lodash')
var config = require('data').config

var FOOTBALL = config.sportId === 'football'

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.3.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    if (!FOOTBALL) {
        return prev
    }
    return _.defaultsDeep(
        {
            jersey: {
                options: {
                    CLSU: {
                        type: 'none',
                    },
                },
            },
        },
        prev
    )
}
