module.exports = function reuseLoader (loader) {
    var currentUrl
    var currentPromise
    // var currentContent

    return {
        load: function (url) {
            if(url == currentUrl) {
                return currentPromise
            }
            currentUrl = url
            loader.cancel()
            currentPromise = loader.load(url)
                // .then(function(newContent){
                //     currentContent = newContent
                // })

            return currentPromise
        },
        // getContent: function(){ return currentContent },
    }
}
