var data = require('./../data')
var _ = require('lodash')

var renderCacheTimestamp = '__RENDER_CACHE_TIMESTAMP__'

function getAssetPath(garment, path) {
    return (
        '/assets/' +
        garment.clothingType +
        '/' +
        garment.modelId +
        path +
        '?' +
        renderCacheTimestamp
    )
}

function getTextureQuery(productId, design, textureSide) {
    return {
        size: { width: 2048, height: 2048 },
        textureSide: textureSide,
        productId: productId,
        design: design,
        // debugSVG: true,
    }
}

function shake(data) {
    if (_.isObject(data)) {
        var local = data
        local = _.mapValues(local, shake)
        local = _.omit(local, function(value, key) {
            return value === 'none' || value == null
        })
        local = _.omit(local, function(value, key) {
            return _.isEmpty(value)
        })
        return local
    }
    return data
}

module.exports = function getResourceDefs(designData) {
    var outsideData = shake(designData[designData.clothingType])
    var insideData = outsideData

    var garment = data.findGarment(designData.designTemplate, outsideData)
    var isReversible = garment.isReversible
    var isReversed = isReversible && designData.isReversed === 'on'

    if (isReversible) {
        insideData = shake(designData[designData.clothingType + 'Inside'])
        insideData.model = outsideData.model
        insideData.colorAreas = {
            area1: outsideData.colorAreas.area2,
            area2: outsideData.colorAreas.area2,
            area3: outsideData.colorAreas.area3,
        }
    }

    return {
        textures: {
            outside: getTextureQuery(
                designData.designTemplate,
                isReversed ? insideData : outsideData,
                'outside'
            ),
            inside: getTextureQuery(
                designData.designTemplate,
                isReversed ? outsideData : insideData,
                isReversible ? 'outside' : 'inside'
            ),
            normals: getAssetPath(garment, '_normals.png'),
        },
        models: {
            base: {
                url: getAssetPath(
                    garment,
                    '_output' + (isReversible ? '_reversible' : '') + '.obj'
                ),
                scale: garment.clothingType === 'pant' ? 0.75 : 1,
                position: {
                    x: 0,
                    y: 0.05,
                    z: 0,
                },
                isReversible: isReversible,
                isReversed: isReversed,
            },
        },
    }
}
