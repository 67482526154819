var THREE = require('three.js-xdomain')
var Q = require('q')
var _ = require('lodash')

var renderCacheTimestamp = '__RENDER_CACHE_TIMESTAMP__'

var getImageLoader = require('./loaders/getImageLoader')
var getModelLoader = require('./loaders/getModelLoader')
var reuseLoader = require('./loaders/reuseLoader')

/**
 *  Loads content defined in resourceDefs into Three.js objects defined in sceneDef
 */
module.exports = function getContentConnectors(sceneDefs){
    var connectorConfig = [
        {
            path: 'textures.outside',
            loader: reuseLoader(getImageLoader({crossOrigin: 'Anonymous'})),
            apply: applyTextureAsset,
        },
        {
            path: 'textures.inside',
            loader: reuseLoader(getImageLoader({crossOrigin: 'Anonymous'})),
            apply: applyTextureAsset,
        },
        {
            path: 'textures.normals',
            loader: reuseLoader(getImageLoader({crossOrigin: 'Anonymous'})),
            apply: applyTextureAsset,
        },
        {
            path: 'models.base',
            loader: reuseLoader(getModelLoader()),
            apply: applyModelAsset,
        },
    ]

    var connectors = _.map(connectorConfig, function(connector){
        return _.assign({}, connector, {
            target: _.get(sceneDefs, connector.path),
            resourceDef: null,
            content: null,
        })
    })

    return {
        update: function update(resourceDefs) {
            return Q.all(_.map(connectors, function(connector){
                connector.resourceDef = _.get(resourceDefs, connector.path)
                
                if(connector.resourceDef) {
                    var url = connector.resourceDef.url || connector.resourceDef
                    if(_.isObject(url)){
                        url = getTexturePath(url)
                    }
                    return connector.loader.load(url)
                } else {
                    return Q(null)
                }
            }))
            .then(function(resources){
                _.each(connectors, function(connector, index){
                    connector.apply(sceneDefs, connector, resources[index])
                })
            })
        }
    }
}

function getTexturePath(query) {
  return (
      '/render/2d?q=' +
      encodeURIComponent(JSON.stringify(query)) +
      '&' +
      renderCacheTimestamp
  )
}


function applyTextureAsset(sceneDefs, connector, newContent){  
    var texture = connector.target

    if(connector.content === newContent) {
        return
    }
    if(connector.content){
        texture.image = null
        connector.content = null
        texture.needsUpdate = true
    }

    if(!newContent) {
        return
    }
    texture.image = newContent
    texture.needsUpdate = true
}

function applyModelAsset(sceneDefs, connector, newContent){
    var container = connector.target

    if(connector.content === newContent) {
        updateModelVisibility(sceneDefs, connector, newContent)
        return
    }
    if(connector.content){
        container.remove(connector.content)
        connector.content = null
    }

    if(!newContent) {
        return
    }

    container.add(newContent)
    connector.content = newContent
    updateModelAsset(sceneDefs, connector, newContent)
    updateModelVisibility(sceneDefs, connector, newContent)
}

function updateModelAsset(sceneDefs, connector, newContent){
    var scale = connector.resourceDef.scale
    var position = connector.resourceDef.position
    newContent.scale.set(scale, scale, scale)
    newContent.position.set(position.x, position.y, position.z)
    
    newContent.traverse(function (child) {
        if ( child instanceof THREE.Mesh ) {
            child.castShadow = true
            child.receiveShadow = true
            if(connector.resourceDef.isReversible){
                if(_.startsWith(child.name, 'frontFacing')){
                    child.material = sceneDefs.materials.outside
                } else if(_.startsWith(child.name, 'backFacing')){
                    child.material = sceneDefs.materials.inside
                }
            } else {
                child.material = sceneDefs.materials.base
            }
        }
    })
}

function updateModelVisibility(sceneDefs, connector, newContent){  
  newContent.traverse(function (child) {
      if ( child instanceof THREE.Mesh ) {
          if(connector.resourceDef.isReversible){            
              if(_.includes(child.name, 'isReversed-')){
                  child.visible = _.includes(child.name, 'isReversed-' + connector.resourceDef.isReversed)
              }
          }
      }
  })
}
