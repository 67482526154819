var _ = require('lodash')

module.exports = function ($rootScope, $timeout, $window, $document, consoleService, safeApply) {

    var WebglPreview = require('../WebglPreview')
    var sceneUtils = require('../sceneUtils')

    var WebGLAdapter = function WebGLAdapter(options) {
        this.name = 'webgl'
        this.options = _.cloneDeep(options)
        this.isInitialized = false
        this.isInitializationInProgress = false
    }

    WebGLAdapter.prototype.supported = function () {
        try {
            return !! sceneUtils.getRenderer()
        } catch (e) {
            return false
        }
    }

    WebGLAdapter.prototype.initialize = function (scope, callbacks) {
        if (this.isInitialized || this.isInitializationInProgress) {
            return
        }
        this.isInitializationInProgress = true

        this.scope = scope
        this.callbacks = callbacks

        this.webglPreview = new WebglPreview(document.getElementById('webglPreviewContainer'), this.getCallbacks())

        this.webglPreview.initialize()
        $window.addEventListener('resize', _.bind(this._resize, this))
        $rootScope.$on('switch_mobile', _.bind(this._resizeAfterLayout, this))
    }


    WebGLAdapter.prototype._resize = function (e) {
        console.log('_resize', e)
        this.scope.$apply(
            _.bind(function () {
                this.webglPreview.resize()
            }, this)
        )
    }
    WebGLAdapter.prototype._resizeAfterLayout = function (e) {
        console.log('_resize', e)
        this.scope.$apply(
            _.bind(function () {
                $timeout(_.bind(this.webglPreview.resize, this.webglPreview), 1)
            }, this)
        )
    }

    WebGLAdapter.prototype.changeActiveView = function (activeView) {
        this.webglPreview.changeActiveView(activeView)
    }

    WebGLAdapter.prototype.update = function (designData) {
        consoleService.logEvent('WebGLPreview', 'Update')

        this.callbacks.onRender && this.callbacks.onRender()
        this.webglPreview.update(designData)
    }



    WebGLAdapter.prototype.getCallbacks = function () {
        var adapter = this,
            scope = this.scope,
            callbacks = this.callbacks

        return {
            onInitialized: function () {
                consoleService.logEvent('WebGL', 'NotifyCanvasReady')
                adapter.isInitialized = true
                adapter.isInitializationInProgress = false

                safeApply(scope, function () {
                    consoleService.logEvent('WebGLPreview', 'Initialized')
                    callbacks.onInitialized && callbacks.onInitialized()
                })
            },

            onRender: function () {
                safeApply(scope, function () {
                    callbacks.onRender && callbacks.onRender()
                })
            },

            onRendered: function () {
                safeApply(scope, function () {
                    consoleService.logEvent('WebGLPreview', 'NotifyRender')
                    callbacks.onRendered && callbacks.onRendered(true)
                })
            },

            onUserInteracted: function () {
                safeApply(scope, function () {
                    consoleService.logEvent('WebGLPreview', 'UserInteracted')
                    callbacks.onPreviewUserInteracted && callbacks.onPreviewUserInteracted()
                })
            },

            onError: function (message) {
                safeApply(scope, function () {
                    consoleService.reportError('WebGLPreviewError', message)
                })
            },
        }
    }

    WebGLAdapter.prototype.getSnapshots = function () {
        return this.webglPreview.getSnapshots()
    }

    return WebGLAdapter
}
