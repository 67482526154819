var _ = require('lodash')
var config = require('data').config

module.exports = mapConfigData

var SOFTBALL = config.sportId === 'softball'

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.10.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    if (!SOFTBALL) {
        return prev
    }
    return _.defaultsDeep(
        {
            jersey: mapGarment(prev.jersey),
            jerseyInside: mapGarment(prev.jerseyInside),
        },
        prev
    )
}

function mapGarment(prev) {
    if (!prev) {
        return prev
    }
    return {
        decoration: _.mapValues(prev.decoration, mapDeco),
    }
}

function mapDeco(prev, decoName) {
    if (!_.includes(['playerName'], decoName)) {
        return prev
    }
    if (prev.size !== '3') {
        return prev
    }
    return {
        size: '2.5',
    }
}
