var THREE = require('three.js-xdomain')
THREE.ImageUtils.crossOrigin = 'Anonymous'

var _ = require('lodash')
var Q = require('q')

var config = require('../data/config')
var sceneUtils = require('./sceneUtils')
var getResourceDefs = require('./getResourceDefs')
var getContentConnectors = require('./getContentConnectors')


var WebglPreview = module.exports = function(element, callbacks){
    this.domContainer = element
    this.callbacks = callbacks

    this._getSnapshot = _.bind(this._getSnapshot, this) // TODO must find a cleaner way with ES5 to bind these functions to the instance
    this._onControlsChange = _.bind(this._onControlsChange, this)
    this._animate = _.bind(this._animate, this)

    window.preview = this

    this._buildScene()
}

WebglPreview.prototype = {
    _buildScene: function(){
        this.scene = new THREE.Scene()
        this.sceneDefs = sceneUtils.getSceneDefs(this.scene)
        this.contentConnectors = getContentConnectors(this.sceneDefs)
        this.renderer = sceneUtils.getRenderer()
        this.camera = sceneUtils.getCamera()
        this.controls = sceneUtils.getControls(this.camera, this.renderer.domElement)
        sceneUtils.addLights(this.scene)

        this.renderer.setSize(this.domContainer.clientWidth, this.domContainer.clientHeight )
        this.domContainer.appendChild(this.renderer.domElement)
        this.renderer.domElement.style.display = 'inline'

        this.resize()
        this.changeActiveView('front', true)
    },

    initialize: function () {
        this.controls.addEventListener('change', this._onControlsChange)
        this._animate()
        this.callbacks.onInitialized()
    },

    update: function (designData) {
        this.callbacks.onRender()

        var resourceDefs = getResourceDefs(designData)
        window.resourceDefs = resourceDefs
        this._updateResourceDefs(resourceDefs)
    },

    _rerender: function() {
        this._updateResourceDefs(window.resourceDefs)
    },
  
    _updateResourceDefs: function (resourceDefs) {
        this.contentConnectors.update(resourceDefs)
        .then(_.bind(function(){
            this.scene.visible = true
            this.callbacks.onRendered()
        }, this))
        .fail(_.bind(function(error){
            if(error === 'Cancelled') {
                return
            }
            this.scene.visible = false
            this.callbacks.onError(error)
            this.callbacks.onRendered()
        }, this))
    },

    resize: function () {
        this._setSize(this.domContainer.clientWidth, this.domContainer.clientHeight)
    },

    changeActiveView: function (viewName, immediate) {
        var controls = this.controls
        var view = config.viewMap[viewName]
        if(immediate){
            controls.enableDamping = false
        }
        controls.setPolarAngle(Math.PI/2 + view.angles.polar*Math.PI/180)
        controls.setAzimuthalAngle(view.angles.azimuthal*Math.PI/180)
        if(immediate){
            controls.update()
            controls.enableDamping = true
        }
    },

    getViewState: function(){
        return {
            azimuthal: this.controls.getAzimuthalAngle() * 180 / Math.PI,
            polar: this.controls.getPolarAngle() * 180 / Math.PI - 90,
        }
    },

    getSnapshots: function() {
        var controls = this.controls
        var minDistance0 = controls.minDistance
        var target0 = controls.target.clone()
        var position0 = controls.object.position.clone()
        var zoom0 = controls.object.zoom
        controls.enabled = false
        controls.minDistance = controls.maxDistance

        var snapshots = _.map(config.snapshotList, this._getSnapshot)

        controls.minDistance = minDistance0
        controls.target.copy(target0)
        controls.object.position.copy(position0)
        controls.object.zoom = zoom0
        controls.enabled = true

        controls.update()
        this.resize()

        return Q(snapshots)
    },


    _setSize: function (width, height) {
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(width, height )
        this._render()
    },

    _animate: function () {
        requestAnimationFrame(this._animate)
        this.controls.update()
        this._render()
    },

    _render: function () {
        this.renderer.render( this.scene, this.camera )
    },

    _onControlsChange: function(event) { // eslint-disable-line no-unused-vars
        this.callbacks.onUserInteracted()
        this.controls.removeEventListener('change', this._onControlsChange)
    },

    _getSnapshot: function (snapshotRequest){
        this.changeActiveView(snapshotRequest.code, true)
        this._setSize(snapshotRequest.snapshotDetails.width, snapshotRequest.snapshotDetails.height)
        return {
            viewName: snapshotRequest.code,
            imgUrl: this.renderer.domElement.toDataURL()
        }
    },
}
