var _ = require('lodash')
var config = require('data').config

var FOOTBALL = config.sportId === 'football'

module.exports = mapConfigData

function mapConfigData(prev) {
    return {
        dataModelVersion: '4.4.0',
        designData: mapDesignData(prev.designData),
    }
}

function mapDesignData(prev) {
    if (FOOTBALL) {
        return prev
    }
    return _.defaultsDeep({
        designTemplate: renameTemplate(prev.designTemplate),
        jersey: mapGarment(prev.jersey),
        jerseyInside: prev.jerseyInside && mapGarment(prev.jerseyInside),
        pant: mapGarment(prev.pant),
    }, prev)
}

function mapGarment(prev) {
    return {
        model: renameTemplate(prev.model),
    }
}

function renameTemplate(prev) {
    if (prev == null) {
        return prev
    }
    return (
        prev
            //  BASEBALL
            .replace('CSRMT-sublimated', 'CSRMTVF')
            .replace('S9BT-sublimated', 'S9BTVF')
            .replace('HOUSJ-sublimated', 'HOUSJ')
            .replace('CHIJ-sublimated', 'CHIJ')
            .replace('RJOAK-sublimated', 'RJOAK')
            .replace('CP5-sublimated', 'CP5VF')
            .replace('CP5MR-sublimated', 'CP5MRVF')
            .replace('CPRO-sublimated', 'CPROVF')
            .replace('CPK-sublimated', 'CPKVF')

            // SOFTBALL
            .replace('FXCIP-sub', 'FXCIPVF')
            .replace('FXCLP-sub', 'FXCLPVF')
            .replace('FXCLP2-sub', 'FXCLP2VF')
            .replace('FXCLP3-sub', 'FXCLP3VF')
    )
}
